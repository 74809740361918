import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../actions/auth'
import brand from '../../assets/img/brand.png'
import ellipsis from '../../assets/img/ellipsis.svg'


// const AdminDashboard = (props) => {
const AdminDashboard = ({ logout }) => {
  const [showHideSidenav, setShowHideSidenav] = useState('menu')


  // Toggle nav
 function toggleNav() {
   let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
   setShowHideSidenav(css)
 }


 
 return (
    <>
    <header className="header">
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
        <Link 
          style={{margin: '0'}}
          onClick={logout} to='/admin' 
          className="button button--secondary">Log out
        </Link>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>

      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
      </header>

     <menu className={showHideSidenav}>
        <Link to='/panels' className="menu__item">Panels</Link>
        <Link to='/users' className="menu__item">Users</Link>
        <Link to='/admin' onClick={logout} className="menu__item">Log Out</Link>
     </menu>

      <main className="main">
          <section className="section section--full">
            <div className="container tp--sm bp--sm">
              <div className="content content--sm content--center">
                <p>Admin Dashboard</p>

                <div className="buttons buttons--center custom">
                  <Link to='/panels' className="button button--primary"> 
                    <span>Manage Panels</span>
                  </Link> 
                  <Link to='/users' className="button button--primary"> 
                    <span>Manage Users</span>
                  </Link> 
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
  ) 
} 


const mapStateToProps = state => {
  //console.log('State:', state, state.panels)
  return { logout: state }
}

export default connect(
  mapStateToProps,
  { logout }
)(AdminDashboard)
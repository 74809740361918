import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// CHECK IMAGE TYPE
export const checkMimeType = (e) => {
    //getting file object
    let files = e.target.files 
    //console.log('MYME TIPE FILES', files)
    //define message container
    let err = []
    // list allow mime type
    //const types = ['image/png', 'image/jpeg', 'image/gif', 'text/csv']
    const types = ['image/png', 'image/jpeg', 'image/gif']

    // loop access array
    for(let i = 0; i < files.length; i ++) {
         // compare file type find doesn't match
         if (types.every(type => files[i].type !== type)) {
         // create error message and assign to container   
         err[i] = files[i].type + ' is not a supported format\n';
       }
     };

     for(let z = 0; z < err.length; z++) {// if message not same old that mean has error 
         // discard selected file
        toast.error(err[z])
        e.target.value = null
        return false;
    }
   return true;
  }


  // CHECK FILE TYPE
  export const checkMimeTypeCsv = (e) => {
    //getting file object
    let files = e.target.files 
    //console.log('MYME TIPE FILES', files)
    //define message container
    let err = []
    // list allow mime type
    const types = ['text/csv', 'application/vnd.ms-excel']

    // loop access array
    for(let i = 0; i < files.length; i ++) {
         // compare file type find doesn't match
         if (types.every(type => files[i].type !== type)) {
         // create error message and assign to container   
         err[i] = files[i].type + ' is not a supported format\n'
       }
     }

     for(let z = 0; z < err.length; z++) {// if message not same old that mean has error 
         // discard selected file
        toast.error(err[z])
        e.target.value = null
        return false;
    }
   return true;
  }



  // CHECK FILE SIZE
  export const checkFileSize = (e) => {
     let files = e.target.files
     let size = 2000000 
     let err = []; 
     for(let x = 0; x<files.length; x++) {
     if (files[x].size > size) {
     err[x] = files[x].type + ' is too large, please pick a smaller file\n';
    }
  };
  for(let z = 0; z<err.length; z++) {// if message not same old that mean has error 
     // discard selected file
     toast.error(err[z])
     e.target.value = null
     return false;
   }
   return true;
  }


  // CHECK SELECTED FILE COUNT (IF FORM ALLOWS TO CHOSE MORE THAN ONE FILE)
  export const maxSelectFile = (e) => {
    let files = e.target.files
        if (files.length > 1) { 
            const msg = 'Only 1 image can be uploaded at a time'
            e.target.value = null
            toast.warn(msg)
             return false;
        }
        return true;
   }
import { axiosWithAuth } from "../utils/axiosWithAuth"
import { setAlert } from './alert'


import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT
} from './types';


// LOGIN
export const login = (formData, history) => async dispatch => {
 dispatch({ type: LOGIN_START })
 try {
   const res = await axiosWithAuth().post('/admin-login', formData)
   dispatch({
     type: LOGIN_SUCCESS,
     payload: res.data
   })

   // dispatch(setAlert(`Welcome`, 'success'))
   history.push( '/admin-dashboard')
 } catch (err) {
    if(err && err.response !== undefined) {
    const errors = err.response.data.errors
    errors ? errors.forEach(error => dispatch(setAlert(error.msg, 'danger'))) :  
    dispatch(setAlert('Error logging in.', 'danger'))
  } else {
    dispatch(setAlert('Error logging in.', 'danger'))
  }
    dispatch({ type: LOGIN_FAIL })
  }
}


// LOGOUT
export const logout = () => dispatch => {
  dispatch({ type: LOGOUT })
}

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useHistory, Redirect } from 'react-router-dom'
import brand from '../../assets/img/brand.png'
import whiteRight from '../../assets/img/circle-right-white.svg'
import rightWhite from '../../assets/img/circle-right-white.svg' 
import parseJwt from '../../utils/JWTdecoder'

 // STYLES
 const styles = {  
   link: {marginLeft: '5px'},
   icon: {marginLeft: '-12px', marginRight: '8px'},
   show: {display: 'block'},
   hide: {display: 'none'},
 }


export default function Login () {
  const [alert, setAlert] = useState('')
  const [loading, setLoading] = useState('')

  const [visible, setVisible] = useState(true)
  const [isConfirmed, setIsConfirmed] = useState(true)
  const [sending, setSending] = useState(false)

  const [formData, setFormData] = useState({
    email: '',
    password:''
  })

  const history = useHistory()
  const { email, password } = formData

  const changeHandler = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })


  
  const login = async (e) => {
    e.preventDefault()
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    try {
      setLoading(true)
      const res = await axios.post(process.env.REACT_APP_API_URL + '/api/login', 
      {email: email.toLowerCase(), password}
      , config) 

      //setAlert(res.data.msg)
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('sessionExpired', false)
      setLoading(false)
      history.push( '/dashboard')
    } catch (err) {
      window.localStorage.clear()
      setLoading(false)

      if(err && err.response !== undefined) {
        const errors = err.response.data.errors
        errors.forEach(error => setAlert(error.msg))
        errors ? errors.forEach(error => setAlert(error.msg)) :  
        setAlert('Error logging in, please try again later or contact info@livesmart.com for assistance.')
    
        errors.map( error => {
          if (error && error !== undefined ? error.msg === 'Please confirm your account registration from your email to login.' : false) {
            setIsConfirmed(false)
            setVisible(false)
          }
        })
       
      } else {
         setAlert('Error logging in, please try again later or contact info@livesmart.com for assistance.')
      }
    }
 }




  // Resend confirmation email
  const sendNewLink = async (e) => {
    e.preventDefault()
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    try { 
        setSending(true)
        const res = await axios.post(process.env.REACT_APP_API_URL + '/api/resend-conf-token',
        {
          email: email.toLowerCase()
        }, config)
        console.log('send new link res', res)
        setSending(false)

        setAlert('')
        setIsConfirmed(true)
        setAlert('Email has been sent. Please check your inbox')
        setVisible(true)
      } catch (err) {
        setSending(false)
        //setMessage('')
        setAlert(err && err.response !==undefined ? err.response.data.message : 'Please try again later or contact service provider')
        console.log(err, err.response.data.message)
    }
  }

  // CHECK IF JWT IS EXPIRED
  const sessionExpired = localStorage.getItem('sessionExpired')
  useEffect(()=> {
    if (sessionExpired && sessionExpired === 'true') {
      setAlert('Your session is expired. Please authenticate.')
    }
  }, [sessionExpired])


 // Get and parse token 
 const authToken = localStorage.getItem('token')
 const decoded = parseJwt(authToken)
 let user_type
 let exp
 if(decoded){
    user_type = decoded.type
    exp = decoded.exp
 }

 // Redirect if token 
 if(user_type === 'user' && Date.now() <= exp * 1000) {
    return <Redirect to='/dashboard' />
 } else if(user_type === 'admin' && Date.now() <= exp * 1000){
    return <Redirect to='/admin' />
 }


  return (
    <>
    <header className="header" >
     <div className="header__left">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
      <Link to='/' className='nav__home__visitor'>
        <img className="brand" src={brand} alt="Brand Name"/>
      </Link>
       <div className="header__placeholder"></div>
    </header>

     <main className="main">
      <section className="section section--full">
        <div className="container tp--sm bp--sm">
          <div className="content content--sm content--center">
            <p>Log in to view your tests.</p>

            { alert &&  <div className='alert'>{alert}</div> }

            {/* If user is not confirmed */}
            {!isConfirmed ? 
            <div style={visible ? styles.hide : styles.show}>
              <p style={{marginTop: '30px'}}>If you would like to receive a new confirmation link, 
              please enter your email and click the button below.</p> 

              <form onSubmit={sendNewLink}>
              <div className="input__section">
                <input
                  className="input--text" 
                  type='text'
                  placeholder='Email address'
                  name='email'
                  value={email}
                  onChange={changeHandler}
                  required
                />
              </div>

              <Link to='/login' type='button' className="button button--secondary" 
               style={{marginRight: '10px'}}
               onClick={() => setVisible(true)}
              >
                <span>Back to login</span>
              </Link>
              <button  type='submit' className="button button--primary">
              { sending ? <><i style={styles.icon} className="fa fa-refresh fa-spin"></i>Sending</> : 
                <>
                <span>Send link</span>
                <img className="link__icon" src={rightWhite} alt="Submit" />
                </>
               }
             </button>
            </form>
            </div>
            : ''}

            <form onSubmit={login} style={visible ? styles.show : styles.hide}>
              <div className="input__section">
                <input
                  className="input--text" 
                  type='text'
                  placeholder='Email address'
                  name='email'
                  value={email}
                  onChange={changeHandler}
                  required
                />
              </div>
              <div className="input__section">
                <input
                  className="input--text"
                  type='password'
                  placeholder='Password'
                  name='password'
                  value={password}
                  onChange={changeHandler}
                  required
                  minLength='6'
                />
              </div>
              <button className="button button--primary" type="submit">
               { loading ? <><i style={styles.icon} className="fa fa-refresh fa-spin"></i>Loading</> : 
                 <>
                  <span>Log in</span> 
                  <img className="link__icon" src={whiteRight} alt="Submit" />
                 </>
               }
              </button>
            </form>

            <p className="text--mute">New to livesmart?
              <Link to='/signup' style={styles.link}>Sign Up</Link>
            </p>

            <p className="text--mute">Forgot password?
              <Link to='/forgot-password' style={styles.link}>Reset Password</Link>
            </p>
          </div>
        </div>
      </section>
     </main>
    </>
  )
}




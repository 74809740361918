import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { checkMimeType, checkMimeTypeCsv, checkFileSize, maxSelectFile } from './admin-utils/checkFiles'
import { setAlert } from '../../actions/alert'
import { addPanel, getPanels } from '../../actions/panels'
import brand from '../../assets/img/brand.png'
import ellipsis from '../../assets/img/ellipsis.svg'
import { logout } from '../../actions/auth'
import Alert from '../layout/Alert'


  // export default function AddPanel() {
  const AddPanel = ({ addPanel, logout, panels: { panels, error } }) => {
  const [showHideSidenav, setShowHideSidenav] = useState('menu')

  const [alert,  setAlert] = useState('')
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(false)
  const [csvLoading, setCsvLoading] = useState(false)

  const [icon, setIcon] = useState('')
  const [icon_public_id,  setIconPublicId] = useState('')
  const [kit_ids,  setKitIds] = useState('')
  
  const [formData, setFormData] = useState({
    panel_name: '',
    panel_prefix: ''
  })


 // Toggle nav
 function toggleNav() {
  let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
  setShowHideSidenav(css)
 } 

  // GET JWT
  const token = localStorage.getItem('token')
  const history = useHistory()

  // SET FORM DATA ON CHANGE
  const { panel_name, panel_prefix } = formData
  //console.log('formData', formData)
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })


 const submitForm = (e) => {
     e.preventDefault()
    // Add icon & kit ids to the form data
    formData.icon = icon
    formData.icon_public_id = icon_public_id
    formData.kit_ids = kit_ids
    addPanel(formData, history)
 }  



    // SELECT PANEL ICON & SAVE TO THE STATE. UPLOAD ICON TO THE CLOUD
    const onChangeSelectFiles = async e => {
      //let files = e.target.files
      let files = Array.from(e.target.files)
      //console.log(files)
      if(!maxSelectFile(e) || !checkMimeType(e) || !checkFileSize(e)) {
        console.log('unsupported type, size or max file counts')
        return
      } else {
        const data = new FormData()
        data.append('file', files[0])
        data.append('upload_preset', 'ordrs-demo-panel-icons') // upload preset & folder name
        setLoading(true)
        //console.log('data', data)
        const res = await fetch(
          'https://api.cloudinary.com/v1_1/ddwrx0i29/image/upload',
          {
            method: 'POST',
            body: data
          }
        )
        const images = await res.json()
    

        // SET PANEL ICON DATA TO THE STATE
        setIcon(images.secure_url)
        setIconPublicId(images.public_id)
        setLoading(false)
        setAlert('')
      }
    }

    
   
    // SELECT CSV CONVERT IT INTO THE ARRAY ON THE SERVER AND SAVE IT TO THE STATE
    const onChangeSelectCsv = async e => {  
      const config = {
      headers: {
        'Content-Type': 'application/json',
        'token': token
        }
     }
 
     try {
      let files = Array.from(e.target.files)
      if(!maxSelectFile(e) || !checkMimeTypeCsv(e) || !checkFileSize(e)) {
        console.log('unsupported type, size or max file counts')
        return
      } else {
        setCsvLoading(true)
        const data = new FormData()
        data.append('file', files[0])

        // PARSE CVS AND SET IT TO THE STATE
        const res = await axios.post(process.env.REACT_APP_API_URL + '/api/panels/csv', data, config)
        console.log(res)

        // SET PANEL ICON URL TO THE STATE
        setKitIds(res.data.kit_ids)
        setLoading(false)
      }

       setCsvLoading(false)
       setAlert('')

     } catch (err) {
      setCsvLoading(false)
      if(err && err.response !== undefined) {
        const errors = err.response.data.errors
        errors ? errors.forEach(error => setAlert(error.msg)) :  
        setAlert('Error getting data. Please contact info@ebien.com for assistance.')
      }  else {setAlert('Error getting data. Please contact info@ebien.com for assistance.')}
     }
    }



  const iconInput = (e) => {
      switch(true) {
        case loading:
          return <h1>Loading...</h1>
        case icon !== '':
          return (<img src={icon} alt='img' style={{width: '10rem'}} />)
        default:
          return (
            <>
              <input className="input--text" type="file" onChange={onChangeSelectFiles} required/>
              <ToastContainer />
            </>
          )
      }
  }
  


  return (
    <>
     <header className="header">
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
        <Link 
          style={{margin: '0'}}
          onClick={logout} to='/admin' 
          className="button button--secondary">Log out
        </Link>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>

      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
      </header>

      <menu className={showHideSidenav}>
        <Link to='/admin-dashboard' className="menu__item">Dashboard</Link>
        <Link to='/panels' className="menu__item">Panels</Link>
        <Link to='/users' className="menu__item">Users</Link>
        <Link to='/admin' onClick={logout} className="menu__item">Log Out</Link>
      </menu>

    <div className='main'>
      <section className="section">
       <div className="container tp--md bp--md">
        <div className="content">

         {/* <h1 className="section__heading">Register Another Panel</h1> */}
         <div className='section__header' style={{margin:'0 0 60px'}}>
           <h1>Register Another Panel</h1>
           <Link  className="button button--secondary" to='/panels'>Back</Link>
         </div>

         { alert &&  <div className='alert'>{alert}</div> } 
         { success &&  <div className='success'>{success}</div> } 
         <Alert />

       <form className='form' onSubmit={ submitForm }>
         <div className="input__section">
          <label className="input__label" htmlFor="panel_name">Name</label>
          <input
            className="input--text" 
            type='text'
            placeholder='name'
            name='panel_name'
            value={panel_name}
            onChange={e => onChange(e)}
            required
          />
          </div>
          <div className="input__section">
           <label className="input__label" htmlFor="panel_prefix">Prefix</label>
          <input
            className="input--text" 
            type='text'
            placeholder='prefix'
            name='panel_prefix'
            value={panel_prefix}
            onChange={e => onChange(e)}
            required
          />
          </div>

          <div className="input__section">
           <label className="input__label" htmlFor="icon">Icon (png, jpg)</label>
           {iconInput()}
          </div>

          <div className="input__section">
            <label className="input__label" htmlFor="kit_ids">Test kit ids (csv)</label>
            <input className="input--text" type="file" onChange={onChangeSelectCsv} required/>
            <ToastContainer />
          </div>

          <button type='submit' className='button button--primary' style={{marginRight: '15px'}}>Add Panel</button>
       </form>
       </div>
      </div>
     </section>
    </div>
   </>
  )
}


const mapStateToProps = state => {
  return  {
    panels: state.panels
  }
}

export default connect(
  mapStateToProps,
  { addPanel, setAlert, getPanels, logout }
  )(AddPanel)
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from "react-pdf"
import axios from 'axios'
import parseJwt from '../utils/JWTdecoder'
import right from '../assets/img/circle-right-gray.svg'
import left from '../assets/img/circle-left-gray.svg'

import pdf from '../components/test.pdf'
import pdf2 from '../components/test2.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`





export default function TestPdf(props) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [zoomNumber, setZoomNumber] = useState(1) // Starting point
  const [numZoom, setNumZoom] = useState(1.6) // Max size
  const [alert, setAlert] = useState('')
  const [loading, setLoading] = useState(false)
  const [pdfLink, setPdfLink] = useState([]) // Max size
  const [isMobile, setMobile] = useState(window.innerWidth < 600)


  // Get and parse token 
  const token = localStorage.getItem('token')
  const decoded = parseJwt(token)
  let user_id
  //let userEmail
    if(decoded){
      user_id = decoded.user_id
      //userEmail = decoded.email
   }


  // SET STATE ON WINDOW RESIZE 
  const updateMedia = () => {
    setMobile(window.innerWidth < 600)
  }
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  })
 


// GET PDF LINK
const getPdfLink = async () => {
  //const orderId = props.match.params.id
  //console.log('xxx', props, props.orderObj.orderId)

  const id = props.orderObj && props.orderObj.resultsPdfInfo ? props.orderObj.resultsPdfInfo.id : ''

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'token': token
      }
  }
  try {
    setLoading(true)
    const res = await axios.post(process.env.REACT_APP_API_URL + `/api/ordrs-get-pdf`, 
    { id, user_id, orderId: props.orderObj.orderId ? props.orderObj.orderId : ''}
    , config) 
  
    pdfLink.push(res.data)

    // console.log('Get PDF:', res.data)
    // console.log('State:', pdfLink )
    // setSuccess(res.data.msg)
    setLoading(false)
  } catch (err) {
    setLoading(false)
    if(err && err.response !== undefined) {
      const errors = err.response.data.errors
      errors ? errors.forEach(error => setAlert(error.msg)) :  
      setAlert('Error getting data. Please contact info@livesmart.com for assistance.')
    }  else {setAlert('Error getting data. Please contact info@livesmart.com for assistance.')}
  }
}

useEffect(() => {
  getPdfLink()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])


  // Handling loading and error
  if (loading) return (
    <div style={{margin: '200px 0'}}>
      Please wait while your results are loading...
    </div>
   )

   if(props.orderObj && !props.orderObj.resultsPdfInfo) return (
      <p>Test results for this kit are not uploaded yet. Please check back later.</p>
   )
  //  if (error) return <p>Error retrieving data from the server :(</p>


  // Pagination logic
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }


  // Zoom logic
  function changeZoom(offset) {
    setZoomNumber(prevZoomNumber => prevZoomNumber + offset)
  }

  function zoom_in() {
    changeZoom(0.1)
  }

  function zoom_out() {
    changeZoom(-0.1)
  }


  return (
    <>
    <div className='pdf-control-wrapper'>
      {/* Pagination */}
      <div className='pagination'>
        <p className='pagination-page-counter'>Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</p>
        <button
          style={{marginRight: '15px'}}
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          <div style={{
            display: 'flex', 
            justifyContent: 'center',
            flexDirection: "column",
          }}>
           <img className="link__icon" src={left} alt="plus" style={{height: '28px', marginBottom: '3px'}}/> 
            Prev
          </div>
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
         <div style={{
             display: 'flex', 
             justifyContent: 'center',
             flexDirection: "column",
           }}>
           <img className="link__icon" src={right} alt="plus" style={{ height: '28px', marginBottom: '3px'}}/> 
           Next
          </div>
        </button>

        {/* ZOOM */}
       <div className='zoom-wrapper'>
         <div style={{marginBottom: '2px'}}>
         <button
           className='zoom-button'
           type="button"
           disabled={zoomNumber <= 1}
           onClick={zoom_out}
         >
             <i className="fa fa-minus" ></i>
         </button>
         <button
           className='zoom-button plus'
           type="button"
           disabled={zoomNumber >= numZoom}
           onClick={zoom_in}
         >
             <i className="fa fa-plus" ></i>
         </button>
         </div>
         <p className='pagination-page-counter'>Zoom {Math.floor(`${zoomNumber}` * 100)+'%'|| (numZoom ? 1 : '--')}</p>
       </div>

        {/* Download button */}
        <div className='download-button-wrapper'>
           <a href={pdfLink[0] ? pdfLink[0].data.getDownloadSignedUrl.signedUrl : ''} download="pdf">
            Download
            <i className="fa fa-download" style={{ marginLeft: '5px' }}></i>
          </a>
        </div>
       </div>
      </div>
      
      {/* Document */}
      <div>

      {isMobile ? 
       <Document
         className="document"
         file={pdfLink[0] ? pdfLink[0].data.getDownloadSignedUrl.signedUrl : ''}
         onLoadSuccess={onDocumentLoadSuccess}
        >
        <Page 
          pageNumber={pageNumber}
          scale={0.6} 
        />
       </Document>
      :   
      <>
       <Document
         className="document"
         file={pdfLink[0] ? pdfLink[0].data.getDownloadSignedUrl.signedUrl : ''}
         //file={pdf}
         onLoadSuccess={onDocumentLoadSuccess}
        >
        <Page 
         pageNumber={pageNumber}
         scale={zoomNumber} 
        />
       </Document>
       
       {/* DOCUMENT 2 */}
       {/* <Document
         className="document"
        //file={pdfLink[0] ? pdfLink[0].data.getDownloadSignedUrl.signedUrl : ''}
        file={pdf2}
        onLoadSuccess={onDocumentLoadSuccess}
       >
      <Page 
        pageNumber={pageNumber}
        scale={zoomNumber} 
       />
      </Document> */}

      </>

      }
       </div>

    </>
  );
}


     // VALIDATE ACCOUNT DETAILS REGISTRATION FORM
    export const validateAccountDetailsForm = (values, setFormErrors, password2) => {
        let emailError = ''
        let passwordError = ''
        let passwordTwoError = ''
     
        // Email Validation
        if (values.email.toLowerCase().match(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i) === null 
        || values.email.length > 255) { // max length 255 characters
          emailError = "Email is invalid"
        }
  
        // Password Validation
        // Minimum eight characters, at least one letter and one number
        if (values.password.length > 255 // max length 255 characters
        || values.password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/) == null) { 
          passwordError = "Password must contain at least eight characters, at least one letter and one number"
        }
  
        // Blank field validations
        if (!values.email) { // cannot be blank
          emailError = "Must enter a email"
        }
        if (!values.password) { // cannot be blank
          passwordError = "Must enter a password"
        }
        if (!password2) { // cannot be blank
          passwordTwoError= "Please confirm password"
        }
  
        // Setting errors to the state
        if (emailError || passwordError || passwordTwoError) {
          setFormErrors({ emailError, passwordError, passwordTwoError })
          return false
        }
        return true
      }





  // VALIDATE PERSONAL DETAILS REGISTRATION FORM
  export const validatePersonalDetailsForm = (values, phoneNumber, setFormErrors) => {
    let firstNameError = ''
    let lastNameError = ''
    let mmError= '' 
    let ddError= '' 
    let yyyyError= '' 
    let genderError= '' 
    let phoneError= '' 
    let streetAddressError= '' 
    let streetAddressAptError= '' 
    let cityError= '' 
    let stateError= '' 
    let zipCodeError=''

    // First Name & Last Name
    if (!values.first_name) { // cannon be blank
      firstNameError = "Must enter a First Name"
    }
    if (!values.last_name) { // cannon be blank
      lastNameError = "Must enter a Last Name"
    }
    if (values.first_name.match(/[^a-zA-Z_ ]/g) // should not contain special characters or numbers. Spaces allowed.
    //|| values.first_name.match(/[0-9]/gi) !== null // should not contain numbers
    || values.first_name.length > 255) { // max length 255 characters
      firstNameError = "First Name cannot contain special characters or numbers"
    }
    if (values.last_name.match(/[^0-9a-zA-Z_ ]/g) 
    || values.last_name.match(/[0-9]/gi) !== null
    || values.last_name.length > 255) { //  max length 255 characters) 
      lastNameError = "Last Name cannot contain special characters or numbers"
    }

    // DOB validations
    if (values.mm.match(/[^0-9a-zA-Z]/g)) {
      mmError = "Month cannot contain special characters"
    }
    if (values.dd.match(/[^0-9a-zA-Z]/g)) {
      ddError = "Day cannot contain special characters"
    }
    if (values.yyyy.match(/[^0-9a-zA-Z]/g)) {
      yyyyError = "Year cannot contain special characters"
    }

    // must contain at least 2 numbers and 
    // be 2 characters in length
    // Month cannon > 12, Day cannot be > 31
    if(values.mm > 12 
      || values.mm < 1 
      || values.mm.length < 2 
      || values.mm.length > 2
      || values.mm.match(/[0-9]/gi) === null
      || values.mm.match(/[0-9]/gi).length < 2){
			mmError = 'Please enter month in "mm" format'
		}
    if(values.dd > 31
      || values.dd < 1 
      || values.dd.length < 2 
      || values.dd.length > 2
      || values.dd.match(/[0-9]/gi) === null
      || values.dd.match(/[0-9]/gi).length < 2){
			ddError = 'Please enter day in "dd" format'
    }
    
    // Patient should be older than 18
    function underAgeValidate(birthday) {
	    // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
	    let optimizedBirthday = birthday.replace(/-/g, "/")
    	// set date based on birthday at 01:00:00 hours GMT+0100 (CET)
	    let myBirthday = new Date(optimizedBirthday);
    	// set current day on 01:00:00 hours GMT+0100 (CET)
	    let currentDate = new Date().toJSON().slice(0,10)+' 01:00:00';
	    // calculate age comparing current date and birthday
    	let myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000))
	    if(myAge < 18) {
     	    return false
      } else { return true }
     } 

    if(underAgeValidate(`${values.yyyy}/${values.mm}/${values.dd}`) === false ) {
      yyyyError = 'You must be older than 18 to register a kit'
    }

    // Birth year should not be less than 1900
    if(values.yyyy < 1900){
      yyyyError = 'The age of the patient is not compatible with panel'
    }
    // Wrong year format
    if(values.yyyy.length < 4 
      || values.yyyy.length > 4
      || values.yyyy.match(/[0-9]/gi) === null
      || values.yyyy.match(/[0-9]/gi).length < 4){
			yyyyError = 'Please enter year in "yyyy" format'
    }

    // Valid phone number formats:
    // XXX-XXX-XXXX
    // XXX.XXX.XXXX
    // XXX XXX XXXX
    // if(values.phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) === null) {
    //     phoneError = 'Must enter a 10 digit phone number.'
    //  }
    // Valid phone number format:(XXX) XXX-XXXX
    // Phone number cannot start with 0 & 1
    if(phoneNumber && phoneNumber.match(/^\([2-9]{1}\d{2}\)\s?\d{3}-\d{4}$/) === null
      // phoneNumber && phoneNumber.match(/^\(\d{3}\)\s?\d{3}-\d{4}$/) === null
      ) {
        phoneError = 'Must enter a 10 digit phone number in format (XXX) XXX-XXXX.'
     }


     // Street address validation
     // This regex accepts minimum one character. 
     // Characters may include a-z, A-Z alphabets, whitespace, comma(,), dot(.), apostrophe ('), and dash(-) symbols.
     if (values.street_address.match(/^[a-zA-Z0-9\s,. '-]{1,}$/) === null  // should not contain special characters
     || values.street_address.length > 255) { // max length 255 characters
       streetAddressError = "Street Address cannot contain special characters."
     }
     if (values.street_address_apt.match(/^[a-zA-Z0-9\s,. '-]{0,}$/) === null  // should not contain special characters
     || values.street_address.length > 255) { // max length 255 characters
       streetAddressAptError = "Apartment field cannot contain special characters."
     }

     // City validation
     // This regex accepts minimum one character. 
     // Characters may include a-z, A-Z alphabets, whitespace, comma(,), dot(.), apostrophe ('), and dash(-) symbols.
     if (values.city.match(/^[a-zA-Z0-9\s,. '-]{1,}$/) === null  // should not contain special characters
     || values.city.length > 255) { // max length 255 characters
       cityError = "City field cannot contain special characters."
     }

     // US Zip Codes: must be 5 digits
     if (values.zip_code.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/) === null // mist be 5 digits
     || values.zip_code.length > 255) { // max length 255 characters
       zipCodeError = "Zip Code is incorrect"
     }

    // Setting errors to the state
    if ( firstNameError || lastNameError || mmError || ddError || yyyyError || genderError || 
      phoneError || streetAddressError || streetAddressAptError || cityError || stateError || zipCodeError) {
      setFormErrors({
        firstNameError, lastNameError, mmError, ddError, yyyyError, genderError, 
        phoneError, streetAddressError, streetAddressAptError, cityError, stateError, zipCodeError
      })
      return false
    }
    return true
  }



// VALIDATE USER ACCOUNT DETAILS UPDATE FORM
 export const validateAccountUpdateForm = (setFormErrors, phone, street_address, street_address_apt, city, zip_code) => {
    let phoneError = '' 
    let streetAddressError = '' 
    let streetAddressAptError = '' 
    let cityError = '' 
    let zipCodeError = ''

    // Valid phone number formats:
    // XXX-XXX-XXXX
    // XXX.XXX.XXXX
    // XXX XXX XXXX
    if(phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/) === null) {
        phoneError = 'Must enter a 10 digit phone number.'
     }

     // Street address validation
     // This regex accepts minimum one character. 
     // Characters may include a-z, A-Z alphabets, whitespace, comma(,), dot(.), apostrophe ('), and dash(-) symbols.
     if (street_address.match(/^[a-zA-Z0-9\s,. '-]{1,}$/) === null  // should not contain special characters
     || street_address.length > 255) { // max length 255 characters
       streetAddressError = "Street Address cannot contain special characters."
     }
     if (street_address_apt ? street_address_apt.match(/^[a-zA-Z0-9\s,. '-]{0,}$/) === null : false// should not contain special characters
     || street_address.length > 255) { // max length 255 characters
       streetAddressAptError = "Apartment field cannot contain special characters."
     }

     // City validation
     // This regex accepts minimum one character. 
     // Characters may include a-z, A-Z alphabets, whitespace, comma(,), dot(.), apostrophe ('), and dash(-) symbols.
     if (city.match(/^[a-zA-Z0-9\s,. '-]{1,}$/) === null  // should not contain special characters
     || city.length > 255) { // max length 255 characters
       cityError = "City field cannot contain special characters."
     }

     // US Zip Codes: must be 5 digits
     if (zip_code.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/) === null // mist be 5 digits
     || zip_code.length > 255) { // max length 255 characters
       zipCodeError = "Zip Code is incorrect"
     }

    // Setting errors to the state
    if (phoneError || streetAddressError || streetAddressAptError || cityError || zipCodeError) {
      setFormErrors({ phoneError, streetAddressError, streetAddressAptError, cityError, zipCodeError})
      return false
    }
    return true
  }



// VALIDATE PASSWORD
export const validatePassword = (setFormErrors, password, password2) => {
    let passwordError = ''
    // let passwordTwoError = ''
    // Password Validation
    // Minimum eight characters, at least one letter and one number
    if (password.length > 255 // max length 255 characters
      || password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/) == null) { 
      passwordError = "Password must contain at least eight characters, at least one letter and one number"
     }
  
     if(password !== password2) {
         passwordError = "Passwords do not match."
     }
      // Setting errors to the state
     if (passwordError) {
        setFormErrors({ passwordError })
        return false
    }
    return true
 }
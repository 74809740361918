import {
    GET_ICONS_SUCCESS, 
    GET_ICONS_ERROR,

  } from '../actions/types'
  
  const initialState = {
    icons: [],
    error: '',
    loading: true,
  }
  
  export default function(state = initialState, action) {
    const { type, payload } = action
  
    switch (type) {
      // GET ICONS
      case GET_ICONS_SUCCESS:
        return {
          ...state,
          icons: payload,
          loading: false
       }

        // ERRORS
        case GET_ICONS_ERROR:
            return {
              ...state,
              error: payload,
              loading: false
        }
  
      default:
        return state
    }
  }
  
  
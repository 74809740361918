import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import brand from '../../assets/img/brand.png'
import rightWhite from '../../assets/img/circle-right-white.svg' 
import ellipsis from '../../assets/img/ellipsis.svg'
// import account from '../../assets/img/account-silver.svg'
// import signOut from '../../assets/img/sign-out-silver.svg'



export default function RegisterSuccess() {
const[showHideSidenav, setShowHideSidenav] = useState('menu')

 function toggleNav() {
   let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
   setShowHideSidenav(css)
 }

 const logout = () => { 
   window.localStorage.clear()
 }

 return (
    <>
      <header className="header">
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>
      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
     </header>
    
     <menu className={showHideSidenav}>
        <a className='menu__item' href="https://www.livesmart.com/">LiveSmart</a>
        <Link to='/dashboard' className="menu__item">Dashboard</Link>
        <Link to='/add-kit' className="menu__item">Register Kit</Link>
        <Link to='/account-details' className="menu__item">Account Settings</Link>
        <Link to='/login' onClick={logout} className="menu__item">Log Out</Link>
     </menu>

      <main className="main">
       <section className="section section--full">
        <div className="container tp--sm bp--sm">
          <div className="content content--sm content--center">
            <h1>Welcome to LiveSmart</h1>
            <p>You have successfully created your account and registered your test. We’ve sent a verification email to the address you provided. Please click the link in the email to fully activate your account, and receive notifications when your test results are in.</p>
            <Link className="button button--primary" to='/dashboard'>
              <span>Go to my dashboard</span>
              <img className="link__icon" src={rightWhite} alt="Submit"/>
            </Link>
          </div>
        </div>
      </section>
     </main>
    </>
  )
}




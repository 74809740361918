import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
  
  // Panel Delete Confirmation
  export default function confirmDeletePanel(id, name, deletePanel){
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirmation__pop-up'>
            <h1>Are you sure?</h1>
            <p>You want to delete {name}</p>
            <div className="buttons buttons--center custom">
            <button
               className="button button--primary"
               onClick={() => {
                 deletePanel(id)
                 onClose()
               }}
            >
              Yes, Delete it!
            </button>
            <button className="button button--secondary" onClick={onClose}>Cancel</button>
            </div>
          </div>
        )
      }
    })
  }
    // Pre Styled Version 
    // Kept for possible future updates
    // confirmAlert({
    //   title: `Delete ${name}`,
    //   message: 'Are you sure?',
    //   buttons: [
    //     {
    //       label: 'Yes',
    //       onClick: () => deletePanel(id)
    //     },
    //     {
    //       label: 'Cancel',
    //     }
    //   ]
    // })
import axios from 'axios'
import { axiosWithAuth } from "../utils/axiosWithAuth"
import { setAlert } from './alert'


import {
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,

  GET_USER_SUCCESS, 
  GET_USER_ERROR, 

  ARCHIVE_USER_SUCCESS, 
  ARCHIVE_USER_ERROR,

  ACTIVATE_USER_SUCCESS, 
  ACTIVATE_USER_ERROR,
} from '../actions/types'



// GET ALL USERS 
export const getUsers = () => async dispatch => {
  try {
    const res = await axiosWithAuth().get('/users')
    dispatch({
      type: GET_USERS_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    // SET CUSTOM ROUTE ERRORS
    if(err && err.response !== undefined) {
       const errors = err.response.data.errors
       errors ? errors.forEach(error => dispatch(setAlert(error.msg))) :  
       dispatch(setAlert('Error getting data.', 'danger'))
     } else {
       dispatch(setAlert('Error getting data.', 'danger'))
    }
    dispatch({ type: GET_USERS_ERROR })
  }
}


// GET USER 
export const getUser = (id) => async dispatch => {
  try {
    const res = await axiosWithAuth().get(`/users/${id}`)
    dispatch({
      type: GET_USER_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    if(err && err.response !== undefined) {
      return dispatch({
        type: GET_USER_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      })
    } else {
      return dispatch({
        type: GET_USER_ERROR,
        payload: { msg: err.message }
      })
    }
  }
}



// ARCHIVE USER
export const archiveUser = (id) => async dispatch => {
  //console.log('panel and panel id', panel, panel.id)
  try {
    const res = await axiosWithAuth().put(`/users/${id}`, {archived: true})
    dispatch({
      type: ARCHIVE_USER_SUCCESS,
      payload: res.data
    })
    dispatch(setAlert('User successfully archived', 'success'))
  } catch (err) {
    // setEditingPanelId(null)
    if(err && err.response !== undefined) {
       return dispatch({
         type: ARCHIVE_USER_ERROR,
         payload: { msg: err.response.statusText, status: err.response.status }
      })
    } else {
       return dispatch({
         type: ARCHIVE_USER_ERROR,
         payload: { msg: err.message }
      })
    }
  }
}


// ACTIVATE USER
export const activateUser = (id) => async dispatch => {
  //console.log('panel and panel id', panel, panel.id)
  try {
    const res = await axiosWithAuth().put(`/users/${id}`, {archived: false})
    dispatch({
      type: ACTIVATE_USER_SUCCESS,
      payload: res.data
    })
    dispatch(setAlert('User successfully activated', 'success'))
  } catch (err) {
    // setEditingPanelId(null)
    if(err && err.response !== undefined) {
       return dispatch({
         type: ACTIVATE_USER_ERROR,
         payload: { msg: err.response.statusText, status: err.response.status }
      })
    } else {
       return dispatch({
         type: ACTIVATE_USER_ERROR,
         payload: { msg: err.message }
      })
    }
  }
}
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import states from './states-data'
import parseJwt from '../utils/JWTdecoder'
import brand from '../assets/img/brand.png'
import ellipsis from '../assets/img/ellipsis.svg'
import FormErrors from './layout/FormErrors'
import { validateAccountUpdateForm, validatePassword } from '../utils/formValidations'


// STYLES
const styles = {  
    icon: { padding: '0.2rem 3.7rem' },
    iconSaveChanges: { padding: '0.2rem 2.7rem' },
    iconDiscard: { padding: '0.2rem 3.2rem' },
 }


 const AccountDetails  = (props) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    mm: '',
    dd: '',
    yyyy: '',
    gender: '',
    phone: '',
    street_address: '',
    street_address_apt: '',
    city: '',
    state: '',
    zip_code: '',
    email: ''
  })
  const [password, setPassword] = useState('********')
  const [password2, setPassword2] = useState('')
  const [success, setSuccess] = useState('')
  const [alert, setAlert] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingDiscard, setLoadingDiscard] = useState(false)
  const [showHideSidenav, setShowHideSidenav] = useState('menu')

  const [formErrors, setFormErrors] = useState({
    phoneError: '', streetAddressError: '', streetAddressAptError: '', 
    cityError: '', zipCodeError: '', passwordError: '', passwordTwoError: ''
  })
  const { 
    phoneError, streetAddressError, streetAddressAptError, 
    cityError, zipCodeError, passwordError, 
    //passwordTwoError
 } = formErrors
  
  const {
    first_name, last_name, mm, dd, yyyy, gender, phone,
    street_address, street_address_apt, city, state, zip_code, 
    //email 
  } = formData

  const handleChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const passwordChangeHandler = e => setPassword(e.target.value)
 

  // Toggle nav
  function toggleNav() {
    let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
    setShowHideSidenav(css)
  }
 
  const logout = () => { 
    window.localStorage.clear()
  }

  // Get and parse token 
  const token = localStorage.getItem('token')
  const decoded = parseJwt(token)
  let user_id
  if(decoded){
    user_id = decoded.user_id
  }


  // UPDATE ACCOUNT DETAILS
  const update = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }
    try {
      setLoadingSave(true)
      const res = await axios.put(process.env.REACT_APP_API_URL + `/api/users/${user_id}`, formData, config) // production
      setSuccess('User data successfully updated')
 
    } catch (err) {
     setLoadingSave(false)
     if(err && err.response !== undefined) {
      const errors = err.response.data.errors
      errors ? errors.forEach(error => setAlert(error.msg)) :  
      setAlert('Error updating data. Please contact info@livesmart.com for assistance.')
     } else {setAlert('Error updating data. Please contact info@livesmart.com for assistance.')}
    }
  }

  // CLEARING TIMEOUT FOR UPDATE 
  useEffect(() => {
    const timer = setTimeout(() => { 
      setLoadingSave(false) 
      setSuccess('')
    }, 3000)
    return () => clearTimeout(timer)
  }, [update])


  // UPDATE PASSWORD
  const updatePassword = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }
    try {
      setLoading(true)
      const res = await axios.put(process.env.REACT_APP_API_URL + `/api/update-password/${user_id}`, {password}, config) // production
      setSuccess(res.data.success)
      setLoading(false)
    } catch (err) {
        setLoading(false)
      if(err && err.response !== undefined) {
        const errors = err.response.data.errors
        errors ? errors.forEach(error => setAlert(error.msg)) :  
        setAlert('Error updating data. Please contact info@livesmart.com for assistance.')
      }  else {setAlert('Error updating data. Please contact info@livesmart.com for assistance.')}
    }
  }



  // GET DATA
  const getAccountDetails = async () => {
    // const id = props.match.params.id
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }
    try {
      setLoadingDiscard(true)
      const res = await axios.get(process.env.REACT_APP_API_URL + `/api/users/${user_id}`, config) 

      setFormData(res.data)
      setSuccess(res.data.msg)

    } catch (err) {
     setLoadingDiscard(false)
     if(err && err.response !== undefined) {
      const errors = err.response.data.errors
      errors ? errors.forEach(error => setAlert(error.msg)) :  
      setAlert('Error getting data. Please contact info@livesmart.com for assistance.')
     }  else {setAlert('Error getting data. Please contact info@livesmart.com for assistance.')}
    }
  }

  const discardChanges = (e) => {
    e.preventDefault()
    getAccountDetails()
  }

  // CLEARING TIMEOUT FOR GET ACC DETAILS
  useEffect(() => {
    const timer = setTimeout(() => { 
      setSuccess('')
      setLoadingDiscard(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [discardChanges])


  useEffect(() => {
    getAccountDetails()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const updateAccountDetails = (e) => {
    e.preventDefault()
    const isValid = validateAccountUpdateForm(setFormErrors, phone, street_address, street_address_apt, city, zip_code)
    if (isValid) {
      setFormErrors({ 
        phoneError: '', 
        streetAddressError: '', 
        streetAddressAptError: '', 
        cityError: '', 
        zipCodeError: '' 
      })
      // clear form or errors
      update(formData)
    }
  }

  const submitUpdatePassword = (e) => {
    e.preventDefault()
    const isValid = validatePassword(setFormErrors, password, password2)
    if (isValid) {
      setFormErrors({ 
        passwordError: ''
      })
      updatePassword(password)
    }
  }



  const changeHandlerPass2 = e =>
    setPassword2(e.target.value)


  



  return (
    <>
     <header className="header">
       
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>

      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
      </header>

     <menu className={showHideSidenav}>
        <a className='menu__item' href="https://www.livesmart.com/">LiveSmart</a>
        <Link to='/dashboard' className="menu__item">Dashboard</Link>
        <Link to='/add-kit' className="menu__item">Register Kit</Link>
        <Link to='/login' onClick={logout} className="menu__item">Log Out</Link>
     </menu>

    <main className="main">
     <section className="section">
        <div className="container tp--md bp--md">
          <div className="content">
            <h1 className="section__heading">Account Details</h1>

            { success &&  <div className='success'>{success}</div> }
            { alert &&  <div className='alert'>{alert}</div> }

            <form>
              <div className="input__section">
                <label className="input__label" htmlFor="first_name">First name</label>
                <input
                  className="input--text" 
                  type='text'
                  placeholder='Peter'
                  name='first_name'
                  value={first_name}
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="input__section">
                <label className="input__label" htmlFor="last_name">Last name</label>
                <input
                  className="input--text"
                  type='text'
                  placeholder='Parker'
                  name='last_name'
                  value={last_name}
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="input__section">
                <label className="input__label" htmlFor="dob">Date of birth</label>
                <div className="input__group input__group--dob">
                  <input
                    className="input--text" 
                    type='text'
                    placeholder='MM'
                    name='mm'
                    value={mm}
                    onChange={handleChange}
                    disabled
                  />
                 <input
                   className="input--text" 
                    type='text'
                    placeholder='DD'
                    name='dd'
                    value={dd}
                    onChange={handleChange}
                    disabled
                  />
                 <input
                    className="input--text" 
                    type='text'
                    placeholder='YYYY'
                    name='yyyy'
                    value={yyyy}
                    onChange={handleChange}
                    disabled
                  />
                </div>
              </div>
              <div className="input__section">
                <label className="input__label" htmlFor="gender">Gender</label>
                <input
                  className="input--text"
                  type='text'
                  placeholder='gender'
                  name='gender'
                  value={gender}
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="input__section">
                <label className="input__label" htmlFor="phone">Phone number</label>
                <input
                  className={phoneError ? 'input--text error' : 'input--text'}
                  type='text'
                  placeholder='(123) 456-7890'
                  name='phone'
                  value={phone}
                  onChange={handleChange}
                  maxLength='14'
                />
              </div>
              <FormErrors message={phoneError}/>
              <div className="input__section">
                <label className="input__label" htmlFor="street_address">Street address</label>
                <div className="input__group input__group--address">
                  <input
                   className={streetAddressError ? 'input--text error' : 'input--text'}
                    type='text'
                    placeholder='20 Ingram Street'
                    name='street_address'
                    value={street_address}
                    onChange={handleChange}
                  />
                  <input
                    className={streetAddressAptError ? 'input--text sm error' : 'input--text sm'}
                    type='text'
                    placeholder="Apt. / Unit"
                    name='street_address_apt'
                    value={street_address_apt ? street_address_apt : ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <FormErrors message={streetAddressError}/>
              <FormErrors message={streetAddressAptError}/>
              <div className="input__section">
                <label className="input__label" htmlFor="city">City</label>
                <input
                  className={cityError ? 'input--text error' : 'input--text'}
                  type='text'
                  placeholder='Queens'
                  name='city'
                  value={city}
                  onChange={handleChange}
                />
              </div>
              <FormErrors message={cityError}/>
              <div className="input__section">
                <label className="input__label" htmlFor="state">State</label>
                <select className="input--text"  name='state'  value={state}  onChange={handleChange} required>
                <option value='' disabled>New York</option>
						      {states.map(state => {
							       return <option key={state.name}>{state.abbreviation}</option>
						    	})}
                 </select>
              </div>
              <div className="input__section">
                <label className="input__label" htmlFor="zip_code">Zip code</label>
                <input
                  className={zipCodeError ? 'input--text error' : 'input--text'}
                  type='text'
                  placeholder='11375'
                  name='zip_code'
                  value={zip_code}
                  onChange={handleChange}
                  minLength='5'
                  maxLength='5'
                />
              </div>
              <FormErrors message={zipCodeError}/>
              <div className="buttons custom">
               <button className="button button--secondary" 
                       type='button' 
                       onClick={discardChanges} 
               >
               { loadingDiscard ?
                   <>
                     <i style={styles.iconDiscard} className="fa fa-refresh fa-spin"></i>
                   </> : 
                  <span>Discard changes</span>
                }

               </button>
               <button 
                    className="button button--primary" 
                    type='submit' 
                    onClick={updateAccountDetails} 
                    >
                 { loadingSave ?
                   <>
                     <i style={styles.iconSaveChanges} className="fa fa-refresh fa-spin"></i>
                   </> : 
                   <span>Save changes</span>
                  }
               </button>
              </div>
            </form>
          </div>

          <form>
           <h1 className="section__heading">Update Password</h1>
           <div className="input__section">
           <label className="input__label" htmlFor="email">Password</label>
           <div className="input__group">
            <input
              className={passwordError ? 'input--text error' : 'input--text'}
              type='password'
              placeholder='password'
              name='password'
              value={password}
              onChange={passwordChangeHandler}
              required
              minLength='8'
            />
            <input
              className={passwordError ? 'input--text error' : 'input--text'}
              type='password'
              placeholder='Confirm password'
              name='password2'
              value={password2}
              onChange={changeHandlerPass2}
              required
              minLength='8'
            />
            </div>
            </div>
            <FormErrors message={passwordError}/>
            <div className="buttons">
             <button 
               className="button button--primary"
               type='submit' 
               onClick={submitUpdatePassword }
             >
               { loading ?
                  <>
                   <i style={styles.icon} className="fa fa-refresh fa-spin"></i>
                  </> : 
                 <span>Change password</span>
                }
             </button>
            </div>
           </form>

        </div>
       </section>
      </main>
    </>
  )
}



export default AccountDetails


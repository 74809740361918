import React, {useEffect, useState } from 'react'
import Spinner from '../layout/Spinner'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getPanelById, getUnusedKitIds } from '../../actions/panels'
import { logout } from '../../actions/auth'
import brand from '../../assets/img/brand.png'
import ellipsis from '../../assets/img/ellipsis.svg'
import Alert from '../layout/Alert'



const Panel = ({ getPanelById, getUnusedKitIds, logout, match, panels: { panel, unusedKitIds, loading, error } })  => {
 const [showHideSidenav, setShowHideSidenav] = useState('menu')

 useEffect(() => {
    getPanelById(match.params.id)
    getUnusedKitIds(match.params.id)
  }, [getPanelById, getUnusedKitIds, match.params.id])


 // Toggle nav
 function toggleNav() {
    let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
    setShowHideSidenav(css)
  }


  // Handling loading
  if (loading) return <Spinner/>

  return (
    <>
     <header className="header">
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
        <Link 
          style={{margin: '0'}}
          onClick={logout} to='/admin' 
          className="button button--secondary">Log out
        </Link>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>

      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
      </header>

      <menu className={showHideSidenav}>
        <Link to='/admin-dashboard' className="menu__item">Dashboard</Link>
        <Link to='/panels' className="menu__item">Panels</Link>
        <Link to='/users' className="menu__item">Users</Link>
        <Link to='/admin' onClick={logout} className="menu__item">Log Out</Link>
      </menu>

    <div className='main'>
     <section className="section">
      <div className="container tp--sm bp--md">
        <div className="content">

         {/*{error &&  <div className='alert'>{error.msg}</div>} */}

          <div className='section__header'>
           <h1>{panel.panel_name}</h1>
           <Link  className="button button--secondary" to='/panels'>Back</Link>
          </div>

          <div className='panel__info'>
            <p>Prefix: {panel.panel_prefix}</p>    
            <p>To remove unused kit Ids, upload CSV with only used kit ids to the Panel.</p>
          </div>

          <Alert />

            <div className="admin-panel__details">
  
             <div>
                <h2 style={{marginBottom: '10px'}}>All</h2>
                {panel.kit_ids && panel.kit_ids.map(kit_id => (
                  <div key={kit_id}>
                    <span>{kit_id}</span>
                  </div>
                ))}
             </div>    

             <div>
               <h2 style={{marginBottom: '10px'}}>Unused</h2>
               {unusedKitIds.unused_kit_ids && unusedKitIds.unused_kit_ids.map(kit_id => {
                return <div key={kit_id}><span>{kit_id}</span><br/></div>
               })}
             </div>

             <div>
              <h2 style={{marginBottom: '10px'}}>Used</h2>
              {unusedKitIds.used_kit_ids && unusedKitIds.used_kit_ids.map(kit_id => {
                return <div key={kit_id}><span>{kit_id}</span><br/></div>
              })}
             </div>

            </div>   

          
          </div>
         </div>
        </section>
       </div>
      </>
    )
  }


const mapStateToProps = state => {
 //console.log('STATE', state)
  return  {
    panels: state.panels
  }
}


export default connect(
  mapStateToProps,
  { getPanelById, getUnusedKitIds, logout }
)(Panel)
import React from "react"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import "./App.scss"
// REDUX
import { Provider } from 'react-redux'
import store from './store'
// UTILS
import PrivateRoute from './utils/PrivateRoute'
import NotFound from './components/layout/NotFound'
// USER 
import Landing from './components/Landing'
import Register from './components/auth/Register'
import Login from './components/auth/Login'
import ConfirmRegister from './components/auth/ConfirmRegister'
import ForgotPassword from './components/auth/ForgotPassword'
import ResetPassword from './components/auth/ResetPassword'
import KitRegister from './components/auth/KitRegister'
import Dashboard from "./components/Dashboard"
import Pdf from "./components/Pdf"
import AccountDetails from "./components/AccountDetails"
import AddKit from "./components/AddKit"
// ADMIN 
import AdminRoute from './utils/AdminRoute'
import AdminLogin from './components/admin/auth/AdminLogin'
import AdminDashboard from './components/admin/AdminDashboard'
import AddPanel from './components/admin/AddPanel'
import Panels from './components/admin/Panels'
import Panel from './components/admin/Panel'
import Users from './components/admin/Users'
import User from './components/admin/User'
import ForgotPassAdmin from './components/admin/auth/ForgoPassAdmin'
import ResetPassAdmin from './components/admin/auth/ResetPassAdmin'

import pdf from './components/PdfView'



function App() {
  return (
     <Provider store={store}>
      <Router>
       <Switch> 
         {/* USER ROUTES */}
         <Route exact path='/pdf' component={pdf} /> 


         <Route exact path='/' component={Landing} /> 
         <Route exact path='/signup' component={KitRegister} />
         <Route exact path='/register' component={Register} />
         <Route exact path='/login' component={Login} />
         <Route exact path='/confirm-register/:token' component={ConfirmRegister} />
         <Route exact path='/forgot-password' component={ForgotPassword} />
         <Route exact path='/reset-password/:token' component={ResetPassword} />
         <PrivateRoute exact path='/dashboard' component={Dashboard} />
         <PrivateRoute exact path='/pdf/:id' component={Pdf} />
         <PrivateRoute exact path='/account-details' component={AccountDetails} />
         <PrivateRoute exact path='/add-kit' component={AddKit} />
         
         {/* ADMIN ROUTES */}
         <Route exact path='/admin' component={AdminLogin} />
         <AdminRoute exact path='/add-panel' component={AddPanel} />
         <AdminRoute exact path='/admin-dashboard' component={AdminDashboard} />
         <AdminRoute exact path='/panels' component={Panels} />
         <AdminRoute exact path='/panel/:id' component={Panel} />
         <AdminRoute exact path='/users' component={Users} />
         <AdminRoute exact path='/user/:id' component={User} />

         <Route exact path='/forgot-password-admin' component={ForgotPassAdmin} />
         <Route exact path='/reset-password-admin/:token' component={ResetPassAdmin} />

        <Route component={NotFound} /> 
       </Switch>
      </Router>
     </Provider>
  )
}


export default App



import { combineReducers } from 'redux'
import alert from './alert'
import auth from './auth'
import panels from './panels'
import icons from './icons'
import users from './users'
 
export default combineReducers({
  alert,
  auth,
  panels,
  icons,
  users
})
import axios from 'axios'
import { axiosWithAuth } from "../utils/axiosWithAuth"
import { setAlert } from './alert'



import {
  GET_PANELS_SUCCESS, 
  GET_PANELS_ERROR, 

  GET_PANEL_BY_ID_SUCCESS,
  GET_PANEL_BY_ID_ERROR,

  POST_PANEL_SUCCESS, 
  POST_PANEL_ERROR, 

  DELETE_PANEL_SUCCESS, 
  DELETE_PANEL_ERROR,

  UPDATE_PANEL_SUCCESS, 
  UPDATE_PANEL_ERROR,

  GET_UNUSED_KIT_IDS_SUCCESS,
  GET_UNUSED_KIT_IDS_ERROR,
} from '../actions/types'



// GET ALL PANELS
export const getPanels = () => async dispatch => {
  try {
    const res = await axiosWithAuth().get('/panels')
    dispatch({
      type: GET_PANELS_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    // SET CUSTOM ROUTE ERRORS
    if(err && err.response !== undefined) {
       const errors = err.response.data.errors
       errors ? errors.forEach(error => dispatch(setAlert(error.msg))) :  
       dispatch(setAlert('Error getting panels.', 'danger'))
     } else {
       dispatch(setAlert('Error getting panels.', 'danger'))
    }
    dispatch({ type: GET_PANELS_ERROR })
  }
}


// GET PANEL BY ID
export const getPanelById = (id) => async dispatch => {
  try {
    const res = await axiosWithAuth().get(`/panels/${id}`)
    dispatch({
      type: GET_PANEL_BY_ID_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    dispatch({ type: GET_PANEL_BY_ID_ERROR })
    if(err && err.response !== undefined) {
      return dispatch({
        type: GET_PANEL_BY_ID_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      })
    } else {
      return dispatch({
        type: GET_PANEL_BY_ID_ERROR,
        payload: { msg: err.message }
      })
    }
  }
}


// POST PANEL
export const addPanel = (formData, history) => async dispatch => {
  //console.log('browser history in action:', history)
  try {
    const res = await axiosWithAuth().post('/panels', formData)
    dispatch({
      type: POST_PANEL_SUCCESS,
      payload: res.data
    })

    dispatch(setAlert('Panel successfully added', 'success'))
    history.push('/panels')
  } catch (err) {
    // SET CUSTOM ROUTE ERRORS
    if(err && err.response !== undefined) {
      const errors = err.response.data.errors
      errors ? errors.forEach(error => dispatch(setAlert(error.msg))) :  
      dispatch(setAlert('Error adding panel.', 'danger'))
    } else {
      dispatch(setAlert('Error adding panel.', 'danger'))
    }
    dispatch({ type: POST_PANEL_ERROR })
  }
}



// DELETE PANEL
export const deletePanel = id => async dispatch => {
  try {
    await axiosWithAuth().delete(`/panels/${id}`)
    dispatch({
      type: DELETE_PANEL_SUCCESS,
      payload: id
    })
    dispatch(setAlert('Panel removed', 'success'))
  } catch (err) {
   // SET CUSTOM ROUTE ERRORS
   if(err && err.response !== undefined) {
     const errors = err.response.data.errors
     errors ? errors.forEach(error => dispatch(setAlert(error.msg, 'danger'))) :  
     dispatch(setAlert('Error deleting panel.', 'danger'))
   } else {
     dispatch(setAlert('Error deleting panel.', 'danger'))
   }
   dispatch({ type: DELETE_PANEL_ERROR })
  }
}


// UPDATE PANEL
export const updatePanel = panel => async dispatch => {
  //console.log('panel and panel id', panel, panel.id)
  try {
    const res = await axiosWithAuth().put(`/panels/${panel.id}`, panel)
    dispatch({
      type: UPDATE_PANEL_SUCCESS,
      payload: res.data
    })
    dispatch(setAlert('Panel data successfully updated', 'success'))
  } catch (err) {
    // SET CUSTOM ROUTE ERRORS
   if(err && err.response !== undefined) {
     const errors = err.response.data.errors
     errors ? errors.forEach(error => dispatch(setAlert(error.msg, 'danger'))) :  
     dispatch(setAlert('Error updating panel.', 'danger'))
   } else {
     dispatch(setAlert('Error updating panel.', 'danger'))
   }
     dispatch({ type: UPDATE_PANEL_ERROR })
  }
}



// GET PANEL UNUSED KIT IDS
export const getUnusedKitIds = (id) => async dispatch => {
  try {
    const res = await axiosWithAuth().get(`/kits/unused/${id}`)
    dispatch({
      type: GET_UNUSED_KIT_IDS_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    if(err && err.response !== undefined) {
      return dispatch({
        type: GET_UNUSED_KIT_IDS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      })
    } else {
      return dispatch({
        type: GET_UNUSED_KIT_IDS_ERROR,
        payload: { msg: err.message }
      })
    }
  }
}
import React, { useState } from 'react'
import axios from 'axios'
import states from '../states-data'
import { Link } from 'react-router-dom'
import brand from '../../assets/img/brand.png'
import rightWhite from '../../assets/img/circle-right-white.svg' 
import left from '../../assets/img/circle-left-silver.svg' 
import FormErrors from '../layout/FormErrors'
import normalizePhone from '../../utils/normalizePhone'
import { validatePersonalDetailsForm } from '../../utils/formValidations'


// STYLES
const styles = {  
  icon: { marginLeft: '-12px', marginRight: '8px' }
}



// REGISTRATION STEP - 3
const RegisterPersonalDetails  = (props) => {
  const [alert, setAlert] = useState('')
  const [loading, setLoading] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [checkbox, setCheckBox] = useState(false)
  console.log('checkbox', checkbox)

  const [formErrors, setFormErrors] = useState({
    firstNameError: '', lastNameError: '',
    mmError: '', ddError: '', yyyyError: '', 
    genderError: '', phoneError: '', streetAddressError: '', streetAddressAptError: '', 
    cityError: '', stateError: '', zipCodeError: ''
  })

  const { firstNameError, lastNameError, mmError, ddError, yyyyError, genderError, 
    phoneError, streetAddressError, streetAddressAptError, cityError, stateError, zipCodeError
  } = formErrors

  const { values, handleChange } = props
  // HANDLE PHONE INPUT
  const changeHandler = e => setPhoneNumber(normalizePhone(e.target.value, phoneNumber))
  // HANDLE CHECKBOX
  const handleCheck = (e) => {
    let isChecked = e.target.checked
    setCheckBox(isChecked)
  }
  
  const test_kit_id = localStorage.getItem('test_kit_id')
  const panelPrefix = localStorage.getItem('panelPrefix')

  const prev = e => {
    e.preventDefault()
    props.prevStep()
  }


 // COMPLETE REGISTER 
 // SEND MULTI STEP FORM DATA TO API
 const completeRegister = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  try {
      // REGISTER A USER
      setLoading(true)
      const res = await axios.post(process.env.REACT_APP_API_URL + '/api/register', 
      // values, 
      {
        first_name: values.first_name, 
        last_name: values.last_name, 
        mm: values.mm, 
        dd: values.dd, 
        yyyy: values.yyyy, 
        gender: values.gender.toUpperCase(),
        phone: phoneNumber,
        street_address: values.street_address, 
        street_address_apt: values.street_address_apt ? values.street_address_apt : null, 
        city: values.city, 
        state: values.state,
        zip_code: values.zip_code, 
        accept_terms_conditions: checkbox,
        email: values.email.toLowerCase(), 
        password: values.password 
      },
      config)
    
      // POST KIT ID
      await axios.post(process.env.REACT_APP_API_URL + '/api/kits', // production
      {
        user_id: res.data.id, 
        test_kit_id: test_kit_id.toUpperCase(),
      } , config)
      
       // Set Token
       localStorage.setItem('token', res.data.token)
       localStorage.setItem('sessionExpired', false)
       
      // CREATE ORDER IN ORDRS API
       createOrderInOrdrs(res.data.id) 

      } catch (err) {
        setLoading(false)
        if(err && err.response !== undefined) {
          const errors = err.response.data.errors
          errors ? errors.forEach(error => setAlert(error.msg)) :  
          setAlert('Error creating order. Please check the data entered or contact info@livesmart.com for assistance.')
         }  else {setAlert('Error creating order. Please check the data entered or contact info@livesmart.com for assistance.')}
      } 
  }



  // CREATE ORDER IN ORDRS API
  const createOrderInOrdrs = async (userResId) => {

    try {
       await axios.post(process.env.REACT_APP_API_URL + '/api/ordrs-post-order', 
       {
         // panelPrefix: test_kit_id.slice(0, 5),
         panelPrefix: panelPrefix,
         orderId: test_kit_id.toUpperCase(),
         gender: values.gender.toUpperCase(),
         state: values.state.toUpperCase(),
         firstName: values.first_name,
         lastName: values.last_name,
         dob: `${values.mm}/${values.dd}/${values.yyyy}`,
         phone: phoneNumber,
         addressLine1: values.street_address,
         addressLine2: values.street_address_apt ? values.street_address_apt : null,
         city: values.city,
         zip: values.zip_code, 
         email: values.email.toLowerCase()
       }
      )

       // SEND EMAIL, SET CONFIRMATION TOKEN AND TOKEN EXP DATE FOR THE USER
       axios.post(process.env.REACT_APP_API_URL + '/api/setConfToken', {id: userResId})
 
       // Set loading to false and redirect to the register success page
       setLoading(false)
       props.nextStep()
    } catch(err) {
      // Sending user to the dashboard even if there is an error & show error in the dashboard
      setLoading(false)
      props.nextStep()
    }
  }



  const submitForm = e => {
  e.preventDefault()
  const isValid = validatePersonalDetailsForm(values, phoneNumber, setFormErrors)
  if (isValid) {
    setFormErrors({ 
      firstNameError: '', lastNameError: '',
      mmError: '', ddError: '', yyyyError: '', 
      genderError: '', phoneError: '', streetAddressError: '', streetAddressAptError: '', 
      cityError: '', stateError: '', zipCodeError: ''
   })
    completeRegister(values)
    console.log('values:', values) 
   } 
  }






  return (
    <>
    <header className="header">

     <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
             <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
      <Link to='/' className='nav__home'>
        <img className="brand" src={brand} alt="Brand Name"/>
      </Link>

      <div className="header__right">
        <button onClick={prev} className="nav__link">
          <img className="link__icon" src={left} alt="Previous" />
          <span>Previous</span>
        </button>
      </div>
    </header>

    <main className="main">
     <section className="section">
        <div className="container tp--md bp--md">
          <div className="content">
            <h1 className="section__heading">Personal Info</h1>

            { alert &&  <div className='alert'>{alert}</div> }

            <form onSubmit={submitForm}>
              <div className="input__section">
                <label className="input__label" htmlFor="firstname">First name</label>
                <input
                  className={firstNameError ? 'input--text error' : 'input--text'}
                  type='text'
                  placeholder='Peter'
                  name='first_name'
                  defaultValue={values.first_name}
                  onChange={handleChange('first_name')}
                  maxLength='128'
                  required
                  />
              </div>
              <FormErrors message={firstNameError}/>
             
              <div className="input__section">
                <label className="input__label" htmlFor="lastname">Last name</label>
                <input
                  className={lastNameError ? 'input--text error' : 'input--text'}
                  type='text'
                  placeholder='Parker'
                  name='last_name'
                  defaultValue={values.last_name}
                  onChange={handleChange('last_name')}
                  maxLength='128'
                  required
                />
              </div>
              <FormErrors message={lastNameError}/>

              <div className="input__section">
                <label className="input__label" htmlFor="dob">Date of birth</label>
                <div className="input__group input__group--dob">
                  <input
                    className={mmError ? 'input--text error' : 'input--text'}
                    type='text'
                    placeholder='MM'
                    name='mm'
                    defaultValue={values.mm}
                    onChange={handleChange('mm')}
                    maxLength='128'
                    required
                  />
                 <input
                    className={ddError ? 'input--text error' : 'input--text'}
                    type='text'
                    placeholder='DD'
                    name='dd'
                    defaultValue={values.dd}
                    onChange={handleChange('dd')}
                    maxLength='128'
                    required
                  />
                 <input
                    className={yyyyError ? 'input--text error' : 'input--text'}
                    type='text'
                    placeholder='YYYY'
                    name='yyyy'
                    defaultValue={values.yyyy}
                    onChange={handleChange('yyyy')}
                    maxLength='128'
                    required
                  />
                </div>
              </div>
              <FormErrors message={mmError}/>
              <FormErrors message={ddError}/>
              <FormErrors message={yyyyError}/>
              <div className="input__section">
                <label className="input__label" htmlFor="gender">Gender</label>
                <select  
                  className={genderError ? 'input--text error' : 'input--text'}
                  name='gender'  
                  defaultValue={values.gender} 
                  onChange={handleChange('gender')} 
                  placeholder="Select one" 
                  required
                >
                  <option value='' disabled>Select one</option>
					      	<option>Male</option>
					      	<option>Female</option>
                </select>
               </div>

              <div className="input__section">
                <label className="input__label" htmlFor="phone">Phone number</label>
                <input
                  className={phoneError ? 'input--text error' : 'input--text'}
                  type='text'
                  placeholder='(123) 456-7890'
                  name='phoneNumber'
                  value={phoneNumber}
                  onChange={changeHandler}
                  maxLength='16'
                  required
                />
              </div>
              <FormErrors message={phoneError}/>
              <div className="input__section">
                <label className="input__label" htmlFor="street_address">Street address</label>
                <div className="input__group input__group--address">
                  <input
                    className={streetAddressError ? 'input--text error' : 'input--text'}
                    type='text'
                    placeholder='20 Ingram Street'
                    name='street_address'
                    defaultValue={values.street_address}
                    onChange={handleChange('street_address')}
                    maxLength='128'
                    required
                  />
                  <input
                    //className="input--text sm" 
                    className={streetAddressAptError ? 'input--text sm error' : 'input--text sm'}
                    type='text'
                    placeholder="Apt. / Unit"
                    name='street_address_apt'
                    defaultValue={values.street_address_apt}
                    onChange={handleChange('street_address_apt')}
                    maxLength='128'
                  />
                </div>
              </div>
              <FormErrors message={streetAddressError}/>
              <FormErrors message={streetAddressAptError}/>
              <div className="input__section">
                <label className="input__label" htmlFor="city">City</label>
                <input
                  className={cityError ? 'input--text error' : 'input--text'}
                  type='text'
                  placeholder='Queens'
                  name='city'
                  defaultValue={values.city}
                  onChange={handleChange('city')}
                  maxLength='128'
                  required
                />
              </div>
              <FormErrors message={cityError}/>
              <div className="input__section">
                <label className="input__label" htmlFor="state">State</label>
                <select 
                  className={stateError ? 'input--text error' : 'input--text'}
                  name='state'  
                  defaultValue={values.state}  
                  onChange={handleChange('state')} 
                  maxLength='128'
                  required
                  >
                <option value='' disabled>Select one</option>
						      {states.map(state => {
							       return <option key={state.name}>{state.abbreviation}</option>
						    	})}
                 </select>
              </div>
              <div className="input__section">
                <label className="input__label" htmlFor="gender">Zip code</label>
                <input
                  className={zipCodeError ? 'input--text error' : 'input--text'}
                  type='text'
                  placeholder='11375'
                  name='zip_code'
                  defaultValue={values.zip_code}
                  onChange={handleChange('zip_code')}
                  maxLength='5'
                  required
                />
              </div>
              <div className="input__section">
               <label className="input__label" htmlFor="terms"></label>
               <input 
                 type="checkbox" 
                 name="terms"
                 value='terms'
                 onChange={e => handleCheck(e)}
                 //onChange={e => setCheckBox(e.target.value)} // No need for handler
                 required
                />
                <a className='terms__link'
                   href='https://www.livesmart.com/pages/terms-and-conditions' 
                   target="_blank"
                   style={{marginLeft: '15px', textDecoration: 'none'}}>
                   I accept the Terms and Conditions
                </a>
              </div>
              <FormErrors message={zipCodeError}/>
              <div className="buttons">
                <button className="button button--primary" type="submit"> 
                  { loading ? <><i style={styles.icon} className="fa fa-refresh fa-spin"></i>Loading</> : 
                    <>
                     <span>Next</span>
                     <img className="link__icon" src={rightWhite} alt="Submit"/>
                    </>
                   }
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      </main>
    </>
  )
}



export default RegisterPersonalDetails



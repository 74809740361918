import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import brand from '../../assets/img/brand.png'
import rightWhite from '../../assets/img/circle-right-white.svg' 
import Spinner from '../layout/Spinner'

// STYLES
const styles = {  
  icon: { marginLeft: '-12px', marginRight: '8px' },
  success: {
    margin: '0 auto',
    marginTop: '15px',
    width: '100%',
    padding: '8px',
    backgroundColor: '#D4EDDA',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center'
  },
  alert: {
    margin: '0 auto',
    marginTop: '15px',
    width: '100%',
    padding: '8px',
    backgroundColor: '#F8D7DA',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center'
  }
}


const ConfirmRegister = (props) => {
  const [loading, setLoading] = useState(false)
  const [alreadyConfirmed, setAlreadyConfirmed] = useState(false)
  
  // const [sending, setSending] = useState(false)
  // const [message, setMessage] = useState('')
  // const [alert, setAlert] = useState('')


  // Checking link token and confirm user if it is not expired
  const confirmAccount = async () => {
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    const { match: { params: { token } } } = props
    try {
      setLoading(true)
      await axios.put(process.env.REACT_APP_API_URL + '/api/update-conf-token', 
      {
        confirmationToken: token
      }, config) 
      setLoading(false)
      
    } catch (error) {
      setLoading(false)
      if (error.response !== undefined ? error.response.data.message === 'user already confirmed' : true) {
        setAlreadyConfirmed(true)
      } 
    }
  }

  useEffect( () => {
    confirmAccount()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


 // Handling loading
 if (loading) return <Spinner/>


 // If link is already used 
 if(alreadyConfirmed) {
    return (
      <main className="main">
       <section className="section section--full">
        <div className="container tp--sm bp--sm">
         <div className="content content--sm content--center">
          {/* <h2>There is a small problem</h2>  
          <p>The invitation link isn't valid. Perhaps you already used it?</p> */}
          <h2>Sorry!</h2> 
          <p>It looks like this link has already been used. Please contact info@livesmart.com for assistance.</p>
         </div>
         </div>
        </section>
      </main>
    )
  }
  
  // If success
  return (
     <>
      <header className="header">
      <div className="header__left">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
      <Link to='/' className='nav__home__visitor'>
        <img className="brand" src={brand} alt="Brand Name"/>
      </Link>
      <div className="header__placeholder"></div>
      </header>
       <div className="header__placeholder"></div>
      <main className="main">
       <section className="section section--full">
        <div className="container tp--sm bp--sm">
         <div className="content content--sm content--center">
            <p style={styles.success}>Your account has been successfully confirmed, please log in.</p>
              <Link to='/login' className="button button--primary">
                <span>Log in</span>
                <img className="link__icon" src={rightWhite} alt="Submit" />
              </Link>
            </div>
         </div>
        </section>
       </main>
      </>
    )
}



export default ConfirmRegister



import axios from 'axios'
import { axiosWithAuth } from "../utils/axiosWithAuth"
import { setAlert } from './alert'



import {
  GET_ICONS_SUCCESS, 
  GET_ICONS_ERROR, 

} from '../actions/types'



// GET ALL PANELS
export const getIcons = () => async dispatch => {
  try {
    const res = await axiosWithAuth().get('/panels/icons')
    dispatch({
      type: GET_ICONS_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    // SET CUSTOM ROUTE ERRORS
    if(err && err.response !== undefined) {
       const errors = err.response.data.errors
       errors ? errors.forEach(error => dispatch(setAlert(error.msg))) :  
       dispatch(setAlert('Error getting panel icons.', 'danger'))
     } else {
       dispatch(setAlert('Error getting panel icons.', 'danger'))
    }
    dispatch({ type: GET_ICONS_ERROR })
  }
}


export default function dateFormat(date) {
    if (!date || date === 'Pending') { return 'Pending' }
    return (
    (((new Date(date).getMonth() > 8) ? (new Date(date).getMonth() + 1) : 
    ('0' + (new Date(date).getMonth() + 1))) + '/' + ((new Date(date).getDate() > 9) ? 
    new Date(date).getDate() : 
    ('0' + new Date(date).getDate())) + '/' + new Date(date).getFullYear())
    )
  }


  
import React from "react";
import { Link, Redirect } from "react-router-dom";
import brand from "../assets/img/brand.png";
import parseJwt from "../utils/JWTdecoder";

const Landing = () => {
  // Get and parse token
  const authToken = localStorage.getItem("token");
  const decoded = parseJwt(authToken);
  let user_type;
  let exp;
  if (decoded) {
    user_type = decoded.type;
    exp = decoded.exp;
  }

  if (user_type === "user" && Date.now() <= exp * 1000) {
    return <Redirect to="/dashboard" />;
  } else if (user_type === "admin" && Date.now() <= exp * 1000) {
    return <Redirect to="/admin" />;
  }

  return (
    <>
      <header className="header">
        <div className="header__left">
          <ul className="breadcrumbs">
            <li className="breadcrumb">
              <i className="fa fa-angle-left" />
              <a href="https://livesmart.health/">Back to LiveSmart</a>
            </li>
          </ul>
        </div>
        <Link to="/" className="nav__home__visitor">
          <img className="brand" src={brand} alt="Brand Name" />
        </Link>
        <div className="header__placeholder"></div>
      </header>
      <main className="main">
        <section className="section section--full">
          <div className="container tp--sm bp--sm">
            <div className="content content--sm content--center">
              <p>
                Welcome to LiveSmart. Please sign in to view results and register
                additional tests, or register your first test kit to create a
                new account.
              </p>
              <div className="buttons buttons--center custom">
                <Link to="/login" className="button button--primary">
                  <span>Log In</span>
                </Link>
                <Link to="/signup" className="button button--primary">
                  <span>New User</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Landing;

import React, { useState } from 'react'
import axios from 'axios'
import { useHistory, Link } from 'react-router-dom'
import brand from '../../assets/img/brand.png'
import rightWhite from '../../assets/img/circle-right-white.svg' 
import left from '../../assets/img/circle-left-silver.svg'
import FormErrors from '../layout/FormErrors'
import { validateAccountDetailsForm } from '../../utils/formValidations'


// STYLES
const styles = {  
  icon: { marginLeft: '-12px', marginRight: '8px' },
}

 
 // REGISTRATION STEP - 2
 const RegisterAccountDetails = (props) => {
  const [alert, setAlert] = useState('')
  const [password2, setPassword2] = useState('')
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({
    emailError: '', 
    passwordError: '',
    passwordTwoError: ''
  })
  const { emailError, passwordError, passwordTwoError } = formErrors
  const { values, handleChange } = props
  const history = useHistory()


  // Check if email is already used
  const checkEmail = async (email) => {
    try {
      setLoading(true)
      await axios.post(process.env.REACT_APP_API_URL + '/api/users/check/email', email)
      setLoading(false)
      props.nextStep()
    } catch (err) {
      setLoading(false)
      if(err && err.response !== undefined) {
        const errors = err.response.data.errors
        errors.forEach(error => setAlert(error.msg, 'danger'))
      } else {
         setAlert('Error registering in, please try again later', 'danger')
      }
    }
  }

  // Next step
  const next = e => {
    e.preventDefault()
    const isValid = validateAccountDetailsForm(values, setFormErrors, password2)
    if (isValid) {
      setFormErrors({ emailError: '', passwordError: '', passwordTwoError: '' })
      if (values.password !== password2) {
        setAlert('Passwords do not match.')
        return
      } else {
        setAlert('')
        checkEmail({ email: values.email.toLowerCase() })
        //console.log(values)
      }
    }
  }

  // Previous step
  const prev = e => {
    e.preventDefault()
    history.push('/signup')
  }


  const changeHandler = e =>
    setPassword2(e.target.value)



  return (
    <>
    <header className="header">

    <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>

      <div className="header__right">
        <button onClick={prev} className="nav__link" style={styles.link}>
          <img className="link__icon" src={left} alt="Previous" />
          <span>Previous</span>
        </button>
      </div>
    </header>

       
       <main className="main">
        <section className="section">
         <div className="container tp--md bp--md">
           <div className="content">
            <h1 className="section__heading">Account Details</h1>

            { alert &&  <div className='alert'>{alert}</div> }

             <form className='htmlForm' onSubmit={next}>
              <div className="input__section">
                <label className="input__label" htmlFor="email">Email address</label>
                <input
                  className={emailError ? 'input--text error' : 'input--text'}
                  type='text'
                  placeholder="peter.parker@gmail.com"
                  name='email'
                  defaultValue={values.email}
                  onChange={handleChange('email')}
                  required
                  minLength='8'
                />
              </div>
              <FormErrors message={emailError}/>
              <div className="input__section">
                <label className="input__label" htmlFor="password">Password</label>
                <input
                  className={passwordError ? 'input--text error' : 'input--text'}
                  type='password'
                  name='password'
                  defaultValue={values.password}
                  onChange={handleChange('password')}
                  required
                  minLength='8'
                />
              </div>
              <FormErrors message={passwordError}/>
              <div className="input__section">
                <label className="input__label" htmlFor="password-conf">Confirm Password</label>
                <input
                  className="input--text"
                  type='password'
                  name='password2'
                  value={password2}
                  onChange={changeHandler}
                  required
                  minLength='8'
               />
              </div>
              <FormErrors message={passwordTwoError}/>
              <div className="buttons">
               <button className="button button--primary" type="submit">
                  { loading ? <><i style={styles.icon} className="fa fa-refresh fa-spin"></i>Loading</> : 
                    <>
                     <span>Next</span>
                     <img className="link__icon" src={rightWhite} alt="Submit"/>
                    </>
                   }
                </button>              
               </div>
             </form>

            </div>
         </div>
        </section>
      </main>
    </>
  )
}


export default RegisterAccountDetails


import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import parseJwt from '../utils/JWTdecoder'
// Import images
import brand from '../assets/img/brand.png'
import rightWhite from '../assets/img/circle-right-white.svg' 
import ellipsis from '../assets/img/ellipsis.svg'



// STYLES
const styles = {  
   icon: { marginLeft: '-12px', marginRight: '8px' },
   alert: {
   margin: '0 auto',
   marginTop: '15px',
   width: '100%',
   padding: '8px',
   backgroundColor: '#F8D7DA',
   borderRadius: '4px',
   display: 'flex',
   justifyContent: 'center'
  }
}


const AddKit = (props) => {
  const [alert, setAlert] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [loading, setLoading] = useState(false)
  const[showHideSidenav, setShowHideSidenav] = useState('menu')

  // Get and parse token 
  const token = localStorage.getItem('token')
  const decoded = parseJwt(token)
  let user_id
  //let userEmail
  if(decoded){
    user_id = decoded.user_id
    //userEmail = decoded.email
  }
  
  const [formData, setFormData] = useState({
    user_id,
    test_kit_id: ''
  })

  const { test_kit_id } = formData
  const history = useHistory()

  const changeHandler = e =>
  setFormData({ ...formData, [e.target.name]: e.target.value })


  const {
    first_name, last_name, mm, dd, yyyy, gender, phone,
    street_address, street_address_apt, city, state, zip_code, email, 
  } = currentUser

  // Toggle nav
  function toggleNav() {
    let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
    setShowHideSidenav(css)
  }

  const logout = () => { 
    window.localStorage.clear()
  }


  // GET CURRENT USER DATA
  const getAccountDetails = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/api/users/${user_id}`, config) // production
      setCurrentUser(res.data)
    } catch (err) {
      if(err && err.response !== undefined) {
        const errors = err.response.data.errors
        errors ? errors.forEach(error => setAlert(error.msg)) :  
        setAlert('Error getting user data. Please contact info@livesmart.com for assistance.')
       }  else {setAlert('Error getting user data. Please contact info@livesmart.com for assistance.')}
    }
  }


  useEffect(() => {
    getAccountDetails()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  
  // POST KIT
  const postKit = async (panel_prefix) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }
    try {
         setLoading(true)
          // CREATE ORDER IN ORDRS API
         await axios.post(process.env.REACT_APP_API_URL + '/api/ordrs-post-order', 
            {
               // panelPrefix: test_kit_id.slice(0, 5),
               panelPrefix: panel_prefix,
               orderId: test_kit_id.toUpperCase(),
               gender: gender.toUpperCase(),
               state: state.toUpperCase(),
               firstName: first_name,
               lastName: last_name,
               dob: `${mm}/${dd}/${yyyy}`,
               phone: phone,
               addressLine1: street_address,
               addressLine2: street_address_apt ? street_address_apt : null,
               city: city,
               zip: zip_code,
               email: email.toLowerCase()
            }
          )

         // POST KIT
         const res = await axios.post(process.env.REACT_APP_API_URL + '/api/kits', 
         { user_id, test_kit_id: test_kit_id.toUpperCase() }, 
         config) 

         // SEND EMAIL
         axios.post(process.env.REACT_APP_API_URL + '/api/add-kit-email', {email, first_name, last_name}, config) 

         setAlert(res.data.msg)
         setLoading(false)
         history.push( '/dashboard')

         } catch (err) {
           setLoading(false)
           if(err && err.response !== undefined) {
            const errors = err.response.data.errors
            errors ? errors.forEach(error => setAlert(error.msg)) :  
            setAlert('Error registering kit. Please contact info@livesmart.com for assistance.')
           }  else {setAlert('Error registering kit. Please contact info@livesmart.com for assistance.')}
         }
  }


  // Submit form and check if kit id is already registered
  const submitForm = async e => {
     e.preventDefault()
    try {
     // Check if kit is already registered
     await axios.post(process.env.REACT_APP_API_URL + '/api/kits/check/test_kit_id', 
     {test_kit_id: test_kit_id.toUpperCase()})

      // Check if kit id is valid (existing in Panels)
      const panelCheck = await axios.post(process.env.REACT_APP_API_URL + '/api/panels/check', 
      { test_kit_id: test_kit_id.toUpperCase() })

      // Call post kit
      postKit(panelCheck.data.panel_prefix)

    } catch(err) {
       setLoading(false)
       if(err && err.response !== undefined) {
        const errors = err.response.data.errors
        errors ? errors.forEach(error => setAlert(error.msg)) :  
        setAlert('Error registering kit. Please contact info@livesmart.com for assistance.')
       }  else {setAlert('Error registering kit. Please contact info@livesmart.com for assistance.')}
     }
  }
 


  return (
    <>
      <header className="header">
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>
      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
     </header>
    
     <menu className={showHideSidenav}>
        <a className='menu__item' href="https://www.livesmart.com/">livesmart</a>
        <Link to='/dashboard' className="menu__item">Dashboard</Link>
        <Link to='/account-details' className="menu__item">Account Settings</Link>
        <Link to='/login' onClick={logout} className="menu__item">Log Out</Link>
     </menu>

      <main className="main">
       <section className="section">
        <div className="container tp--md bp--md">
          <div className="content">
          <h1 className="section__heading">Register Another Test Kit</h1>
        
            { alert &&  <div style={styles.alert}>{alert}</div> }
           
            <form onSubmit={submitForm}>
              <div className="input__section">
               <label className="input__label" htmlFor="email">Test Kit ID</label>
               <input
                 className="input--text" 
                 type='text'
                 placeholder='Ex: CB-7789338491-D'
                 name='test_kit_id'
                 value={test_kit_id}
                 onChange={changeHandler}
                 required
               />
               </div>
               <div className="input__section">
               <label className="input__label" htmlFor="terms"></label>
               <input 
                 type="checkbox" 
                 name="terms"
                 value='terms'
                 required
                />
                <a className='terms__link'
                   href='https://www.livesmart.com/pages/terms-and-conditions' 
                   target="_blank"
                   style={{marginLeft: '15px', textDecoration: 'none'}}>
                   I accept the Terms and Conditions
                </a>
              </div>
               <div className="buttons">
                <button className="button button--primary" type="submit" style={styles.buttonTestKitId}>
                  { loading ? <><i style={styles.icon} className="fa fa-refresh fa-spin"></i>Loading</> : 
                   <>
                    <span>Register Kit</span>
                    <img className="link__icon" src={rightWhite} alt="Submit" />
                   </>
                  }
                </button>
              </div>
            </form>

          </div>
        </div>
      </section>
     </main>
    </>
  )
}


export default AddKit



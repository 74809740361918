import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import dateFormat from '../utils/dateFormat'
import brand from '../assets/img/brand.png'
import whitePlus from '../assets/img/plus-circle-white.svg' 
import Spinner from './layout/Spinner'
import parseJwt from '../utils/JWTdecoder'
import ellipsis from '../assets/img/ellipsis.svg'
import FormErrors from './layout/FormErrors'
// STYLES
// const styles = { hide: {display: 'none'} }
import { connect } from 'react-redux'
import { getIcons } from '../actions/icons'


const Dashboard = ({ getIcons, icons: { icons } }) => {
  const[showHideSidenav, setShowHideSidenav] = useState('menu')
  const[loading, setLoading] = useState(false)
  const[alert, setAlert] = useState(false)
  const[error, setError] = useState(false)
  const[alertId, setAlertId] = useState('')
  const[orders, setOrders] = useState([])


  // REDUX GET PANELS
  useEffect(() => {
    getIcons()
  }, [])


  // Toggle nav
  function toggleNav() {
   let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
   setShowHideSidenav(css)
  }

  const logout = () => { 
    window.localStorage.clear()
  }


  function showAlert(orderId) {
      setAlertId(orderId)
      setAlert('The results for this test are not yet ready.')
      //  setTimeout(() => {
      //    setAlertId('')
      //  }, 3000)
  }
  useEffect(() => {
    const timer = setTimeout(() => { setAlertId('') }, 3000)
    return () => clearTimeout(timer)
  }, [showAlert])

  // Get and parse token 
  const token = localStorage.getItem('token')
  const decoded = parseJwt(token)
  let user_id
  let userEmail
  if(decoded){
    user_id = decoded.user_id
    userEmail = decoded.email
  }





// GET ORDERS 
const getOrders = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'token': token
      }
  }
  try {
    setLoading(true)
    const res = await axios.post(process.env.REACT_APP_API_URL + `/api/ordrs-get-orders`, { user_id, email: userEmail }, config) 

    res.data.map(order => {
      return orders.push(order)
    }) 
    setLoading(false)
  } catch (err) {
    setLoading(false)
   if(err && err.response !== undefined) {
     const errors = err.response.data.errors
     errors ? errors.forEach(error => setError(error.msg)) :  
     setError('Error getting data. Please contact info@livesmart.com for assistance.')
   }  else {setError('Error getting data. Please contact info@livesmart.com for assistance.')}
  }
}


useEffect(() => {
  getOrders()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  

  // Handling loading
  if (loading) return <Spinner />

  return (
    <>
    <header className="header">
    <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
      <Link to='/' className='nav__home'>
        <img className="brand" src={brand} alt="Brand Name"/>
      </Link>
      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
     </header>
     <menu className={showHideSidenav}>
       <a className='menu__item' href="https://www.livesmart.com/">LiveSmart</a>
       <Link to='/add-kit' className="menu__item">Register Kit</Link>
       <Link to='/account-details' className="menu__item">Account Settings</Link>
       <Link to='/login' onClick={logout} className="menu__item">Log Out</Link>
     </menu>

     <main className="main">
      <section className="section">
       <div className="container tp--sm bp--md">
         <div className="content">
           <div className="section__header">
              <h1>My Tests</h1>
              <Link to='/add-kit' href="#" className="button button--primary">
                <span>Register another test</span>
                <img className="link__icon" src={whitePlus} alt="Register another test" />
              </Link>
          </div>

          { error &&  <div className='alert'>{error}</div> }

         {orders.map(order => ( 
          <div className="orders" key={order.orderId} >
          <div className="orders__item">
            
           <div className="orders__graphic__wrapper">
            {icons.map(panel => (
             order.panelName === panel.panel_name ? 
             <img 
               key={panel.panel_name}
               className="orders__graphic" 
               src={panel.icon}
               alt="icon"
             />
             : ''
            ))}
           
           </div>
            <div className="orders__info">

            { order.resultsPdfInfo ? 
             <Link to={ `/pdf/${order.orderId}` } style={{textDecoration:'none', color:'inherit', cursor:'pointer'}}>
              <div className="orders__header">
                <h2>{order.panelName}</h2>
                  <div className="orders__id">{order.orderId}</div>
               </div>
               <div className="orders__details">

                <div className="orders__detail">
                  <h3>Registered on</h3>
                  <span className="orders__detail__date">           
                    {dateFormat(order.createdAt)}
                  </span>
                </div>

                <div className="orders__detail">
                  <h3>Lab received on</h3>
                  <span className="orders__detail__date">
                    {dateFormat( order.activity[5] ? order.activity[5].createdAt : 'Pending')}
                  </span>
  
                </div>

                <div className="orders__detail">
                  <h3>Results received on</h3>
                  <span className="orders__detail__date">
                    {dateFormat( order.resultsPdfInfo ? order.resultsPdfInfo.createdAt : 'Pending')}
                  </span>
                </div>

               </div>
              </Link>
                 : 
                 <>
                 <div onClick={(() => showAlert(order.orderId))} style={{cursor:'pointer'}}>
                 <div className="orders__header">
                 <h2>{order.panelName}</h2>
                   <div className="orders__id">{order.orderId}</div>
                </div>

                {order.orderId === alertId ? 
                <div style={{marginLeft: '27px'}}><FormErrors message={alert}/></div> : '' }  

                <div className="orders__details">
 
                 <div className="orders__detail">
                   <h3>Registered on</h3>
                   <span className="orders__detail__date">           
                     {dateFormat(order.createdAt)}
                   </span>
                 </div>
 
                 <div className="orders__detail">
                   <h3>Lab received on</h3>
                   <span className="orders__detail__date">
                     {dateFormat(order.activity && order.activity[5] ? order.activity[5].createdAt : 'Pending')}
                   </span>
   
                 </div>
 
                 <div className="orders__detail">
                   <h3>Results received on</h3>
                   <span className="orders__detail__date">
                     {dateFormat(order.resultsPdfInfo ? order.resultsPdfInfo.createdAt : 'Pending')}
                   </span>
                 </div>
 
                </div>
                </div>

                </>
              }
             </div>
            </div>
           </div>

          ))}   
                    
         </div>
        </div>
      </section>
     </main>
    </>
  )
}


const mapStateToProps = state => {
  //console.log('User Dashboard State:', state, state.icons)
  return  {
    icons: state.icons
  }
}


export default connect(
  mapStateToProps,
  { getIcons }
)(Dashboard)
   
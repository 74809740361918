import React, {useEffect, useState } from 'react'
import Spinner from '../layout/Spinner'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUser, archiveUser, activateUser } from '../../actions/users'
import brand from '../../assets/img/brand.png'
import ellipsis from '../../assets/img/ellipsis.svg'
import { logout } from '../../actions/auth'
import Alert from '../layout/Alert'


const User = ({ getUser, archiveUser, activateUser, logout, match, users: { user, loading, error } })  => {
  const [showHideSidenav, setShowHideSidenav] = useState('menu')
 
  useEffect(() => {
     getUser(match.params.id)
   }, [])

   // Toggle nav
   function toggleNav() {
     let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
     setShowHideSidenav(css)
   }



  // Handling loading
  if (loading) return <Spinner/>

  return (
   <>
    <header className="header">
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
        <Link 
          style={{margin: '0'}}
          onClick={logout} to='/admin' 
          className="button button--secondary">Log out
        </Link>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>

      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
    </header>

    <menu className={showHideSidenav}>
     <Link to='/admin-dashboard' className="menu__item">Dashboard</Link>
     <Link to='/panels' className="menu__item">Panels</Link>
     <Link to='/users' className="menu__item">Users</Link>
     <Link to='/admin' onClick={logout} className="menu__item">Log Out</Link>
   </menu>

    <div className='main'>
     <section className="section">
       <div className="container tp--sm bp--md">
        <div className="content">

         {/* {error &&  <div className='alert'>{error.msg}</div>} */}

        <div className="section__header"  style={{margin:'0 0 60px'}}>
         <h1>User Details</h1>
         <Link  className="button button--secondary" to='/users'>Back</Link>
        </div>

        <Alert />

        {user.archived ?
         <button 
           className="button button--secondary"
           style={{cursor:'pointer', margin: '0 15px 25px 0'}} 
           onClick={() => activateUser(user.id)}
          >
           Activate User
         </button> 
         : 
         <button 
          className="button button--primary"
          style={{cursor:'pointer', margin: '0 15px 25px 0'}}
          onClick={() => archiveUser(user.id)}
         >
          Archive User
         </button> 
        }

         <div className="orders__item" key={user.id}>
            <div className="orders__detail">
             <p 
              className={user.archived ? 'user-details__status-archived' : 'user-details__status-active'}
             > 
               User Status: <span>{user.archived ? 'Archived': 'Active'}</span>
             </p>
             <p className='user__details'>Name: <span>{user.first_name} {user.last_name}</span></p>        
             <p className='user__details'>Date of Birth:  <span>{user.mm}/{user.dd}/{user.yyyy}</span></p>        
             <p className='user__details'>Gender: <span>{user.gender}</span></p>        
             <p className='user__details'>Phone: <span>{user.phone}</span></p>     
             <p className='user__details'>Street Address: <span>{user.street_address}</span></p>        
             <p className='user__details'>Street Address 2: <span>{user.street_address_apt}</span></p>        
             <p className='user__details'>City: <span>{user.city}</span></p>        
             <p className='user__details'>State: <span>{user.state}</span></p>     
             <p className='user__details'>Zip Code: <span>{user.zip_code}</span></p>        
             <p className='user__details'>Email: <span>{user.email}</span></p>        
            </div>
             {/* <div>Kit Ids: {
                user.kit_ids && user.kit_ids.map(kit_id => {
                  return <div key={kit_id}><span style={{margin: '5px'}}>{kit_id}</span><br/></div>
                })
             }</div> */}
          </div>

         </div>
        </div>
       </section>
      </div>
     </>
    )
  }


const mapStateToProps = state => {
  //console.log('STATE', state.users)
  return  {
    users: state.users
  }
}


export default connect(
  mapStateToProps,
  { getUser, archiveUser, activateUser, logout }
)(User)
import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import brand from '../../../assets/img/brand.png'
import whiteRight from '../../../assets/img/circle-right-white.svg'


 // STYLES
 const styles = {  
    link: {  marginLeft: '5px' },
    icon: { marginLeft: '-12px', marginRight: '8px' },
    show: {display: 'block', margin: '30px auto'},
    hide: {display: 'none'},
  }


const ForgotPassword = () => {
  const [alert, setAlert] = useState('')
  const [success, setSuccess] = useState('')

  const [loading, setLoading] = useState('')
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)

 

  const handleChange = (event) => {
    setEmail(event.target.value)
  }

  const sendEmail = async (e) => {
    e.preventDefault();
    if (email === '') {
      setAlert(false)
      setSuccess('')
    } else {
      try {
       if(emailSent) {
          setSuccess('')
          setAlert('Email already sent. Please check your inbox.')
          return
        }
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_API_URL + '/api/forgot-password-admin', {email: email.toLowerCase()})
        console.log(response.data)
        if (response.data === 'Email successfully sent. Please check your inbox.') {
          setAlert(false)
          setSuccess('Email successfully sent. Please check your inbox.')
          setLoading(false)
        }
        setLoading(false)
        setEmailSent(true)
      } catch (error) {
        setLoading(false)
        setEmailSent(false)
        if (error.response && error.response.data === "That email address isn't recognized.") {
          setAlert("That email address isn't recognized.")
          setSuccess('')
          setLoading(false)
          setEmailSent(false)
        }
      }
    }
  }


    return (
        <>
         <header className="header">
            <div className="header__left">
             <ul className="breadcrumbs">
              <li className="breadcrumb">
               <i className="fa fa-angle-left"/>
               <Link to="/admin">Back to app</Link>
              </li>
             </ul>
            </div>
            <Link to='/admin' className='nav__home__visitor'>
             <img className="brand" src={brand} alt="Brand Name"/>
            </Link>
            <div className="header__placeholder"></div>
         </header>
          <main className="main">
           <section className="section section--full">
            <div className="container tp--sm bp--sm">
             <div className="content content--sm content--center">
             <p>Please enter your email.</p>

              {alert &&  <div className='alert'>{alert}</div>}
              {success && <div className='success'>{success}</div>}

              <form onSubmit={sendEmail}>
               <div className="input__section">
                 <input
                   className="input--text" 
                   type='text'
                   placeholder='Email address'
                   name='email'
                   value={email}
                   onChange={handleChange}
                   placeholder="Email address"
                   required
                 />
                </div>
                  <Link to='/admin' 
                    onClick={(() => setEmailSent(false))}
                    type='button' className="button button--secondary" 
                    style={{marginRight: '10px'}}>
                    <span>Back to login</span>
                  </Link>
                 <button className="button button--primary" type="submit">
                  { loading ? <><i style={styles.icon} className="fa fa-refresh fa-spin"></i>Sending</> : 
                    <>
                    { emailSent ? <span>Email sent</span> : <span>Send email</span> }
                     <img className="link__icon" src={whiteRight} alt="Submit" />
                    </>
                   }
                 </button>
               </form>
            </div>
           </div>
          </section>
         </main>
      </>
    )
}

export default ForgotPassword

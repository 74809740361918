/* eslint-disable default-case */
import React, { Component } from 'react'
import RegisterAccountDetails from './RegisterAccountDetails'
import RegisterPersonalDetails from './RegisterPersonalDetails'
import RegisterSuccess from './RegisterSuccess'


export class Register extends Component {
  state = {
    step: 1,
    first_name: '',
    last_name: '',
    mm: '',
    dd: '',
    yyyy: '',
    gender: '',
    phone: '',
    street_address: '',
    street_address_apt: '',
    city: '',
    state: '',
    zip_code: '',
    email: '',
    password: '',
  }

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  }

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  }

  // Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value })
    console.log('State From /Register:', this.state)
  }

 

  render() {
    const { step } = this.state
    const {first_name, last_name, mm, dd, yyyy, gender, phone, street_address, street_address_apt, city, state, zip_code, 
    email, password
  } = this.state
    const values = {first_name, last_name, mm, dd, yyyy, gender, phone, street_address, street_address_apt, city, state, zip_code, email, password }
    

    switch (step) {
      case 1:
        return (
          <RegisterAccountDetails 
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        )
      case 2:
        return (
          <RegisterPersonalDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
          />
        )
      case 3:
        return <RegisterSuccess />
    }
  }
}

export default Register

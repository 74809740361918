import {
    GET_PANELS_SUCCESS, 
    GET_PANELS_ERROR,

    GET_PANEL_BY_ID_SUCCESS,
    GET_PANEL_BY_ID_ERROR,

    POST_PANEL_SUCCESS, 
    POST_PANEL_ERROR, 

    DELETE_PANEL_SUCCESS,
    DELETE_PANEL_ERROR,

    UPDATE_PANEL_SUCCESS,
    UPDATE_PANEL_ERROR,

    GET_UNUSED_KIT_IDS_ERROR,
    GET_UNUSED_KIT_IDS_SUCCESS
  } from '../actions/types'
  
  const initialState = {
    panels: [],
    panel: '',
    unusedKitIds: [],
    error: '',
    loading: true,
  }
  
  export default function(state = initialState, action) {
    const { type, payload } = action
  
    switch (type) {
      // GET PANELS
      case GET_PANELS_SUCCESS:
        return {
          ...state,
          // panels: payload.reverse().slice(0, 8),
          panels: payload.reverse(),
          loading: false
       }

       // GET PANEL BY ID
       case GET_PANEL_BY_ID_SUCCESS:
        return {
          ...state,
          panel: payload,
          loading: false,
       }

        // POST PANEL
        case POST_PANEL_SUCCESS:
          return {
            ...state,
            panels: [payload, ...state.panels],
            loading: false,
          }

        // DELETE PANELS
        case DELETE_PANEL_SUCCESS:
          return {
            ...state,
            panels: state.panels.filter(panel => panel.id !== payload),
            loading: false
        }

        // UPDATE PANELS
        case UPDATE_PANEL_SUCCESS:
          return {
            ...state,
            loading: false,
        }

        // GET PANEL UNUSED KIT IDS
        case GET_UNUSED_KIT_IDS_SUCCESS:
          return {
            ...state,
            unusedKitIds: payload,
            loading: false,
        }

        // ERRORS
        case GET_PANELS_ERROR:
        case GET_PANEL_BY_ID_ERROR:
        case POST_PANEL_ERROR:
        case DELETE_PANEL_ERROR:
        case UPDATE_PANEL_ERROR:
        case GET_UNUSED_KIT_IDS_ERROR:
            return {
              ...state,
              error: payload,
              loading: false
        }
  
      default:
        return state
    }
  }
  
  
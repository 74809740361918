import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import brand from '../../../assets/img/brand.png'
import whiteRight from '../../../assets/img/circle-right-white.svg'
import FormErrors from '../../layout/FormErrors'


 // STYLES
 const styles = {  
  link: {marginLeft: '5px' },
  icon: {marginLeft: '-12px', marginRight: '8px' },
  show: {display: 'block', margin: '30px auto'},
  hide: {display: 'none'},
}


const ResetPassAdmin = (props) => {
  const [formData, setFormData] = useState({
    password: '',
    password2: ''
  })
  const [updated, setUpdated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [formErrors, setFormErrors] = useState({
    passwordError: '',
    passwordTwoError: ''
  })
  const { passwordError, passwordTwoError } = formErrors
  const { password, password2 } = formData

  // const handleChange = (event) => {
  //   setPassword(event.target.value)
  // }
  const handleChange = e =>
  setFormData({ ...formData, [e.target.name]: e.target.value })

 // Checking link token if expired
 const checkToken = async () => {
    console.log('PROPS:', props)
    const { match: { params: { token } } } = props
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + '/api/reset-password-admin', 
        {params: { resetPasswordToken: token }}
        )
       console.log('RESPONSE:', response);
      if (response.data.message === 'password reset link a-ok') {
        setLoading(false)
        setError(false)
      } else {
        console.log('error')
      }
    } catch (error) {
      console.log(error.response.data);
      setLoading(false)
      setError(true)
    }
  }

  
  useEffect( () => {
    checkToken()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  


  // Updating password
  const updatePassword = async () => {
    // e.preventDefault()
    const { match: { params: { token } } } = props
    try {
      setLoading(true)
      const response = await axios.put( 
        process.env.REACT_APP_API_URL + '/api/update-password-admin', 
        {
          password,
          resetPasswordToken: token,
        },
      )
      console.log('RESPONSE DATA:', response)
      setLoading(false)
      if (response && response.data.message === 'password updated') {
        setUpdated(true)
        setError(false)
      } else {
        setUpdated(false)
        setError(true)
      }
    } catch (error) {
      setLoading(false)
      setUpdated(false)
      // console.log(error.response.data)
    }
  }


   // Submit Form
   const submitForm = e => {
    e.preventDefault()
    const isValid = validateForm()
    if (isValid) {
      updatePassword()
      setFormErrors({ passwordError: '', passwordTwoError: '' })
      // setFormData({password: '', password2: ''})
    }
   }
    

  // Validate form
  const validateForm = () => {
    let passwordError = ''
    let passwordTwoError = ''

    // Password Validation
    // Minimum 8 characters, at least one letter and one number
    if (password.length > 255 // max length 255 characters
    || password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/) == null) { 
      passwordError = "Password must contain at least eight characters, at least one letter and one number"
    }

    if (!password) { // cannot be blank
      passwordError = "Must enter a password"
    }
    if (!password2) { // cannot be blank
      passwordTwoError= "Please confirm password"
    }
    if (password !== password2) { // Passwords must be equal
      passwordTwoError= 'Passwords do not match.'
    }

    // Setting errors to the state
    if (passwordError || passwordTwoError) {
      setFormErrors({ passwordError, passwordTwoError })
      return false
    }
    return true
  }




  // If link is expired
   if(error) {
       return (
       <main className="main">
        <section className="section section--full">
         <div className="container tp--sm bp--sm">
          <div className="content content--sm content--center">
            <h2 style={{marginBottom: '10px'}}>There is a small problem</h2>  
            <p>The link has expired or incorrect. Would you like to send another link to reset password?</p>
            <Link to='/forgot-password-admin' className="button button--primary">
              <span>Send link</span>
              <img className="link__icon" src={whiteRight} alt="Submit" />
            </Link>
         </div>
        </div>
       </section>
      </main>
     )
   }


  return (
    <>
     <header className="header">
     <div className="header__left">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <Link to="/admin">Back to login</Link>
          </li>
        </ul>
      </div>
      <Link to='/admin' className='nav__home__visitor'>
        <img className="brand" src={brand} alt="Brand Name"/>
      </Link>
      <div className="header__placeholder"></div>
     </header>
      <main className="main">
           <section className="section section--full">
            <div className="container tp--sm bp--sm">
             <div className="content content--sm content--center">
             <p style={updated ? styles.hide : styles.show}>Please enter new password.</p>

             {updated &&  <div className='success'>
                  Your password has been successfully reset, please try logging in again.
              </div>}

             <form onSubmit={submitForm}  style={updated ? styles.hide : styles.show}>
               <div className="input__section" style={{marginBottom: '20px'}}>
                 <input
                   className="input--text" 
                   type='password'
                   placeholder='Password'
                   name='password'
                   value={password}
                   onChange={handleChange}
                   required
                   minLength='8'
                 />
                </div>
                <FormErrors message={passwordError} />
               <div className="input__section" style={{marginBottom: '20px'}}>
                 <input
                   className="input--text" 
                   type='password'
                   placeholder="Confirm password"
                   name='password2'
                   value={password2}
                   onChange={handleChange}
                   required
                   minLength='8'
                 />
                </div>
                <FormErrors message={passwordTwoError} />
                 <button className="button button--primary" type="submit"
                   style={updated ? styles.hide : styles.show}
                 >
                  { loading ? <><i style={styles.icon} className="fa fa-refresh fa-spin"></i>Loading</> : 
                    <>
                     <span>Save</span> 
                     <img className="link__icon" src={whiteRight} alt="Submit" />
                    </>
                   }
                 </button>
               </form>
               <div style={updated ? styles.show : styles.hide}>
                  <Link to='/admin' type='submit' className="button button--primary">
                    <span>Back to login</span>
                    <img className="link__icon" src={whiteRight} alt="Submit" />
                  </Link>
               </div>
              </div>
            </div>
          </section>
         </main>
      </>
    )
}



export default ResetPassAdmin


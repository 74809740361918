import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory, Redirect } from 'react-router-dom'
import parseJwt from '../../../utils/JWTdecoder'
import { login } from '../../../actions/auth'
import brand from '../../../assets/img/brand.png'
import Alert from '../../layout/Alert'
import whiteRight from '../../../assets/img/circle-right-white.svg'



const Login = ({ login, loading }) => {
  const [alert, setAlert] = useState('')
  const [formData, setFormData] = useState({
    email: '',
    password:''
  })
  const history = useHistory()
  const { email, password } = formData

  const changeHandler = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })



 const submitForm = e => {
   e.preventDefault()
   login(formData, history)
 }



// CHECK IF JWT IS EXPIRED
const sessionExpired = localStorage.getItem('sessionExpired')
useEffect(()=> {
  if (sessionExpired && sessionExpired === 'true') {
     setAlert('Your session is expired. Please authenticate.', 'alert')
  }
}, [sessionExpired])


 // Get and parse token 
 const authToken = localStorage.getItem('token')
 const decoded = parseJwt(authToken)
 let user_type
 let exp
 if(decoded){
    user_type = decoded.type
    exp = decoded.exp
 }

 // Redirect if token 
 if(user_type === 'admin' && Date.now() <= exp * 1000) {
    return <Redirect to='/admin-dashboard' />
 } else if(user_type === 'user' && Date.now() <= exp * 1000){
    return <Redirect to='/user' />
 }




return (
  <>
  <header className="header" >
     <div className="header__left">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <Link to='/'>Back to user app</Link>
          </li>
        </ul>
      </div>
      <Link to='/admin' className='nav__home__visitor'>
        <img className="brand" src={brand} alt="Brand Name"/>
      </Link>
       <div className="header__placeholder"></div>
    </header>

    <div className="main">
    <section className="section section--full">
     <div className="container tp--sm bp--sm">
      <div className="content content--sm content--center">
      <h1 className='large text-primary'>Admin Login</h1>
      <p>Please log in to see admin dashboard</p>

      <Alert />
      {alert && <div className='alert'>{alert}</div>}
      
      <form className='form' onSubmit={submitForm}>
      <div className="input__section">
          <input
            className="input--text"
            type='text'
            placeholder='email'
            name='email'
            value={email}
            onChange={changeHandler}
            required
          />
          </div>
          <div className="input__section">
          <input
            className="input--text"
            type='password'
            placeholder='password'
            name='password'
            value={password}
            onChange={changeHandler}
            required
            minLength='8'
          />
          </div>
          <button className="button button--primary" type="submit">
            {loading ? <><i style={{marginLeft: '-12px', marginRight: '8px'}} className="fa fa-refresh fa-spin"></i>Loading</> : 
              <>
               <span>Log in</span> 
               <img className="link__icon" src={whiteRight} alt="Submit" />
              </>
            }
         </button>
        </form>

        <p className="text--mute">Forgot password?
          <Link to='/forgot-password-admin' style={{marginLeft: '5px'}}>Reset Password</Link>
        </p>
       </div>
      </div>
      </section>
     </div>
    </>
  )
}

// const mapStateToProps = state => ({
//    auth: state.auth
// })
const mapStateToProps = state => {
  //console.log('Admin Landing State:', state)
  return  {
    auth: state.auth,
    loading: state.auth.loading
  }
}

export default connect(
  mapStateToProps,
  { login }
)(Login)
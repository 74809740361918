import React, { useState } from "react"
import { Link, Redirect, useHistory } from 'react-router-dom'
import axios from 'axios'
import parseJwt from '../../utils/JWTdecoder'
import brand from '../../assets/img/brand.png'
import arrow from '../../assets/img/arrow-right.svg'


// STYLES
const styles = {  
  link: {  marginLeft: '5px' },
}


 // REGISTRATION STEP - 1
function KitRegister() {
 const history = useHistory()
 const [test_kit_id, setKitId] = useState('')
 const [alert, setAlert] = useState('')
 const [loading, setLoading] = useState(false)

 const changeHandler = e => setKitId(e.target.value)


  // GET DATA
  const checkIfIdExists = async (e) => { 
    e.preventDefault()
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    try {
      setLoading(true)
      // Check if kit is already registered
      await axios.post(process.env.REACT_APP_API_URL + '/api/kits/check/test_kit_id', 
      { test_kit_id: test_kit_id.toUpperCase() }, 
      config)

      // Check if kit id is valid (existing in Panels)
      const res = await axios.post(process.env.REACT_APP_API_URL + '/api/panels/check', 
      { test_kit_id: test_kit_id.toUpperCase() }, 
      config)
   
      localStorage.setItem('panelPrefix', res.data.panel_prefix)
      localStorage.setItem('test_kit_id', test_kit_id.toUpperCase())
      history.push( '/register')
    } catch (err) {
      setLoading(false)
      if(err && err.response !== undefined) {
        const errors = err.response.data.errors
        errors ? errors.forEach(error => setAlert(error.msg)) :  
        setAlert('Error checking data. Please contact info@livesmart.com for assistance.')
       }  else {setAlert('Error checking data. Please contact info@livesmart.com for assistance.')}
    }
  }



 // Get and parse token 
 const authToken = localStorage.getItem('token')
 const decoded = parseJwt(authToken)
 let user_type
 let exp
 if(decoded){
    user_type = decoded.type
    exp = decoded.exp
 }

 
  // Redirect if token 
  if(user_type === 'user' && Date.now() <= exp * 1000) {
     return <Redirect to='/dashboard' />
  } else if(user_type === 'admin' && Date.now() <= exp * 1000){
     return <Redirect to='/admin' />
  }


  return (
    <>
    <header className="header">
      <div className="header__left">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
      <Link to='/' className='nav__home__visitor'>
        <img className="brand" src={brand} alt="Brand Name"/>
      </Link>
      <div className="header__placeholder"></div>
    </header>
      <main className="main">
       <section className="section section--full">
        <div className="container">
          <div className="content content--sm content--center">
            <p>Enter your test kit registration ID below.</p>

            { alert &&  <div className='alert'>{alert}</div> }

            <form action="" onSubmit={checkIfIdExists}>
              <div className="input__group">
               <input 
                 className="input--text input--text--lg"
                 type='text'
                 name='test_kit_id'
                 placeholder='Ex: SC.01-0004'
                 required
                 value={test_kit_id}
                 onChange={changeHandler}
               /> 
               <button className="button button--endcap kit--register__desktop" type="submit"> 
                 { 
                   loading ? <i style={{color: '#ffffff'}} className="fa fa-refresh fa-spin"></i> : 
                   <img className="link__icon" src={arrow} alt="Submit"/>
                 }
               </button> 
              </div>
              <button className="button button--primary kit--register__mobile" type="submit"> 
                 { 
                   loading ? <i style={{color: '#ffffff'}} className="fa fa-refresh fa-spin"></i> : 
                   <><span>Next</span>
                   <img className="link__icon" src={arrow} alt="Submit"/></>
                 }
               </button> 
            </form> 
            <p className="text--mute">
               Already have an account? 
              <Link to='/login' style={styles.link}>Log in</Link>
            </p> 
         </div>
        </div>
      </section>
     </main>
    </>
  )
}

export default KitRegister


     
import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import { checkMimeType, checkMimeTypeCsv, checkFileSize, maxSelectFile } from './admin-utils/checkFiles'
import { getElementError } from '@testing-library/react'



const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
}


const UpdatePanel = (props) => {
  const [modalIsOpen, setIsOpen] = useState(true)
  const [panel, setPanel] = useState(props.panel)
  // let subtitle
  const [alert,  setAlert] = useState('')
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(false)
  const [csvLoading, setCsvLoading] = useState(false)

  const [icon, setIcon] = useState('')
  const [icon_public_id,  setIconPublicId] = useState('')
  const [kit_ids,  setKitIds] = useState('')

  const toggle = () => setIsOpen(!modalIsOpen)
  // GET JWT
  const token = localStorage.getItem('token')

  const handleChanges = e => {
    const { name, value } = e.target
    setPanel({ ...panel, [name]: value })
  }


  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //   subtitle.style.color = '#f00'
  // }

  function closeModal(){
    // setIsOpen(false)
    props.setIdToNull()
    toggle()
  }
  
  const updatePanel = async () => {
    // ADD ICON & KIT IDS TO THE PANEL OBJECT
    panel.icon = icon ? icon : panel.icon
    panel.icon_public_id = icon_public_id ? icon_public_id : panel.icon_public_id
    panel.kit_ids = kit_ids ? kit_ids : panel.kit_ids

    await props.editPanel(panel)
    // console.log('panel', panel)
    props.getPanels()
    closeModal()
  }

   
    

   // FILE UPLOADS

   // SELECT PANEL ICON & SAVE TO THE STATE. UPLOAD ICON TO THE CLOUD
   const onChangeSelectFiles = async e => {
    //let files = e.target.files
    let files = Array.from(e.target.files)
    //console.log(files)
    if(!maxSelectFile(e) || !checkMimeType(e) || !checkFileSize(e)) {
      console.log('unsupported type, size or max file counts')
      return
    } else {
      const data = new FormData()
      data.append('file', files[0])
  
      data.append('upload_preset', 'ordrs-demo-panel-icons') // upload preset & folder name
      setLoading(true)
      //console.log('data', data)
      const res = await fetch(
        'https://api.cloudinary.com/v1_1/ddwrx0i29/image/upload',
        {
          method: 'POST',
          body: data
        }
      )
      const images = await res.json()

      // SET PANEL ICON DATA TO THE STATE
      setIcon(images.secure_url)
      setIconPublicId(images.public_id)
      setLoading(false)
      setAlert('')
    }
  }


  
 
  // SELECT CSV CONVERT IT INTO THE ARRAY IN THE SERVER AND SAVE IT TO THE STATE
  // Do not check for unique kit ids
  const onChangeSelectCsv = async e => {  
    const config = {
    headers: {
      'Content-Type': 'application/json',
      'token': token
      }
   }

   try {
    let files = Array.from(e.target.files)
    if(!maxSelectFile(e) || !checkMimeTypeCsv(e) || !checkFileSize(e)) {
      console.log('unsupported type, size or max file counts')
      return
    } else {
      setCsvLoading(true)
      const data = new FormData()
      data.append('file', files[0])

      // PARSE CVS AND SET IT TO THE STATE
      // console.log('panel', panel)
      const res = await axios.post(process.env.REACT_APP_API_URL + '/api/panels/csv-update', data, config)

      // SET PANEL ICON URL TO THE STATE
      setKitIds(res.data.kit_ids)
      setCsvLoading(false)
    }

     setCsvLoading(false)
     setAlert('')

   } catch (err) {
    setCsvLoading(false)
    if(err && err.response !== undefined) {
      const errors = err.response.data.errors
      errors ? errors.forEach(error => setAlert(error.msg)) :  
      setAlert('Error getting data. Please contact info@ebien.com for assistance.')
    }  else {setAlert('Error getting data. Please contact info@ebien.com for assistance.')}
   }
  } 


  const iconInput = (e) => {
    switch(true) {
      case loading:
        return <h1>Loading...</h1>
      case icon !== '':
        return (<img src={icon} alt='img' style={{width: '10rem'}} />)
      default:
        return (
          <>
            <input className="input--text" type="file" onChange={onChangeSelectFiles} required/>
          </>
        )
    }
  }




    return (
      <div>
        <ToastContainer />
        <Modal
          style={customStyles}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          closeTimeoutMS={500}
          //onAfterOpen={afterOpenModal}
        >
          {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>Update Panel</h2> */}
          <div className='modal__header'>
            <h2>Update Panel</h2>
            <span className='modal__header close' onClick={closeModal}>x</span>
          </div>

          <div className='modal__body'>

          { alert &&  <div className='alert'>{alert}</div> } 
          
          <form className="edit-form" >
          <div className="input__section">
            <label className="input__label" htmlFor="panel_name">Panel Name</label>
            <input
              className="input--text"
              type="text"
              name="panel_name"
              placeholder="Name"
              onChange={handleChanges}
              value={panel.panel_name}
            />
          </div>
          <div className="input__section">
            <label className="input__label" htmlFor="panel_prefix">Panel Prefix</label>
            <input
              className="input--text"
              type="text"
              name="panel_prefix"
              placeholder="Prefix"
              onChange={handleChanges}
              value={panel.panel_prefix}
            />
          </div>

          <div className="input__section">
           <label className="input__label" htmlFor="icon">Icon (png, jpg)</label>
           {iconInput()}
          </div>
          
          <div className="input__section">
            <label className="input__label" htmlFor="kit_ids">Test kit ids (csv)</label>
            <input className="input--text" type="file" onChange={onChangeSelectCsv} required/>
          </div>

          </form>

          </div>
          <div className='modal__footer'>
            <button 
             style={{marginRight: '8px'}}
             className="button__sm button__sm--primary"
             onClick={updatePanel}>Save changes</button>{' '}
            <button 
             className="button__sm button__sm--secondary"
             onClick={closeModal}>Cancel</button>
          </div>
       </Modal>
      </div>
    )
}


export default UpdatePanel




	

import React, { useState, useEffect } from 'react'
import Spinner from './layout/Spinner'
import { Link } from 'react-router-dom'
import brand from '../assets/img/brand.png'
import PdfView from './PdfView'
import dateFormat from '../utils/dateFormat'
import ellipsis from '../assets/img/ellipsis.svg'
import axios from 'axios'
import parseJwt from '../utils/JWTdecoder'



const Pdf = (props) => {
  const[showHideSidenav, setShowHideSidenav] = useState('menu')
  const [alert, setAlert] = useState('')
  const [loading, setLoading] = useState(false)
  const [orderData, setOrderData] = useState({
        id: '',
        orderId: "",
        status: "",
        panelName: "",
        resultsPdfInfo: '',
        resultsPdfInfoCreatedAt : "",
        patientEmail: "",
        patientFirstName: "",
        patientLastName: "",
        reviewAssignedMdFirstName: '',
        reviewAssignedMdLastName: '',
        labName: ''
  })

  const {
     id, orderId, panelName, status, resultsPdfInfo, resultsPdfInfoCreatedAt,
     createdAt, patientEmail, patientFirstName, patientLastName,
     reviewAssignedMdFirstName, reviewAssignedMdLastName, labName,
  } = orderData



  // Get and parse token 
  const token = localStorage.getItem('token')
  const decoded = parseJwt(token)
  let user_id
  if(decoded){ user_id = decoded.user_id }


 
  // GET ORDER BY ID
  const getOrderById = async () => {
    const orderId = props.match.params.id
    //console.log('user & order id:', user_id, orderId)
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      }
    try {
      setLoading(true)
      const res = await axios.post(process.env.REACT_APP_API_URL + `/api/ordrs-get-order`, { user_id, id: orderId }, config) 
      setOrderData({
          id : res.data.data.order.id,
          orderId: res.data.data.order.orderId,
          status: res.data.data.order.status,
          panelName: res.data.data.order.panelName,

          resultsPdfInfo: res.data.data.order.resultsPdfInfo,
          resultsPdfInfoCreatedAt: res.data.data.order.resultsPdfInfo ? res.data.data.order.resultsPdfInfo.createdAt : '',

          patientEmail: res.data.data.order.patient.email,
          patientFirstName: res.data.data.order.patient.firstName,
          patientLastName: res.data.data.order.patient.lastName,

          reviewAssignedMdFirstName: res.data.data.order.reviewAssignedMd.firstName,
          reviewAssignedMdLastName: res.data.data.order.reviewAssignedMd.lastName,

          labName: res.data.data.order.lab.name
      })

      setLoading(false)
    } catch (err) {
      setLoading(false)
    if(err && err.response !== undefined) {
      const errors = err.response.data.errors
      errors ? errors.forEach(error => setAlert(error.msg)) :  
      setAlert('Error getting data. Please contact info@livesmart.com for assistance.')
    }  else {setAlert('Error getting data. Please contact info@livesmart.com for assistance.')}
    }
  }


  useEffect(() => {
    getOrderById()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
 // Handling loading
  if (loading) return <Spinner/>


  function toggleNav() {
    let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
    setShowHideSidenav(css)
  }
 
  const logout = () => { 
    window.localStorage.clear()
  }
    
  
  return (
    <>
      <header className="header">
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
         <li className="breadcrumb">
           <i className="fa fa-angle-left"/>
           <a href="https://livesmart.health">Back to LiveSmart</a>
          </li>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>
      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
     </header>
     <menu className={showHideSidenav}>
        <a className='menu__item' href="https://www.livesmart.com/">livesmart</a>
        <Link to='/dashboard' className="menu__item">Dashboard</Link>
        <Link to='/add-kit' className="menu__item">Register Kit</Link>
        <Link to='/account-details' className="menu__item">Account Settings</Link>
        <Link to='/login' onClick={logout} className="menu__item">Log Out</Link>
     </menu>
 
      <main className="main">
       <section className="section">
         <div className="container lg tp--md bp--md">
          <div className="content">
            <div className="section__header">
               <div>
                <Link to='/dashboard' href="#" className="button button--secondary" style={{marginBottom: '25px'}}>
                   <span>Back to My Orders</span>
                </Link>
                <h1>{panelName ? panelName : ''}</h1>
               </div>
            </div>
            {/* { alert &&  <div style={styles.alert}>{alert}</div> } */}
            <div className="order__wrapper">

              <div className="order__pane">
                <PdfView orderObj={orderData} />
              </div>

              <div className="order__details">
                <div>
                  <h3>Order Id</h3>
                  <div className="order__detail">
                    {orderId ? orderId : ''}
                  </div>
                </div>
                <div>
                  <h3>Lab Facility</h3>
                  <div className="order__detail">
                    {labName ? labName : 'Not assigned'}
                  </div>
                </div>
                <div>
                  <h3>Physician</h3>
                  <div className="order__detail">
                    Dr.
                    <span style={{marginLeft: '4px'}}>
                      {reviewAssignedMdFirstName ?  reviewAssignedMdFirstName : 'Not assigned'}
                    </span>
                    <span style={{marginLeft: '4px'}}>
                      {reviewAssignedMdLastName ? reviewAssignedMdLastName : ''}
                    </span>
                  </div>
                </div>
                <div>
                  <h3>Results Received</h3>
                  <div className="order__detail">
                     {dateFormat(resultsPdfInfoCreatedAt ? resultsPdfInfoCreatedAt : 'Pending')}
                  </div>
                </div>
              </div>
            </div>

         </div>
        </div>
      </section>
     </main>
    </>
  )
}


export default Pdf
//import { withRouter } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Spinner from '../layout/Spinner'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { setAlert } from '../../actions/alert'
import { logout } from '../../actions/auth'
import { getUsers } from '../../actions/users'
import brand from '../../assets/img/brand.png'
import ellipsis from '../../assets/img/ellipsis.svg'
import Alert from '../layout/Alert'



const Users = ({ getUsers, logout, users: { users, loading, error } }) => {
  const [showHideSidenav, setShowHideSidenav] = useState('menu')

   useEffect(() => {
     getUsers()
   }, [])


  // Toggle nav
  function toggleNav() {
    let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
    setShowHideSidenav(css)
  }


  // Handling loading
  if (loading) return <Spinner/>

  return (
    <>
     <header className="header">
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
        <Link 
          style={{margin: '0'}}
          onClick={logout} to='/admin' 
          className="button button--secondary">Log out
        </Link>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>

      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
      </header>


     <menu className={showHideSidenav}>
        <Link to='/admin-dashboard' className="menu__item">Dashboard</Link>
        <Link to='/panels' className="menu__item">Panels</Link>
        <Link to='/admin' onClick={logout} className="menu__item">Log Out</Link>
     </menu>

    <div className='main'>
     <section className="section">
      <div className="container tp--sm bp--md">
        <div className="content">

      <div className="section__header"  style={{margin:'0 0 60px'}}>
       <h1>Users</h1>
       <Link 
         to='/admin-dashboard' 
         className="button button--secondary" 
         style={{margin:'0 0 16px'}}
       >
         Back
       </Link>
      </div>

      <Alert />    

      {users.length < 1 && <p>No users.</p>}

      {users.map(user => {
      return(
        <div key={user.id}>
         <Link to={`/user/${user.id}`} style={{textDecoration: 'none'}}> 
          <div 
           className="orders__item"
           style={{padding: '30px'}}
          >
            <div>
             <h2>{user.first_name} {user.last_name}</h2> 
             <span>{user.email}</span> 
            </div>
          </div>
         </Link>
        {/* <button style={{cursor:'pointer', marginRight: '15px'}} onClick={() => setEditingPanelId(panel.id)}>Edit</button>
        <button style={{cursor:'pointer'}} onClick={() => deletePanel(panel.id)}>Delete</button> */}
        </div>
       )
      })} 
       </div>
      </div>
     </section>
    </div>
    </>
  ) 
} 


const mapStateToProps = state => {
  //console.log('State:', state, state.panels)
  return  {
    users: state.users
  }
}


export default connect(
  mapStateToProps,
  { getUsers, setAlert, logout }
)(Users)


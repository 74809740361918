//import { withRouter } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Spinner from '../layout/Spinner'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../actions/auth'
import { getPanels, deletePanel, updatePanel } from '../../actions/panels'
import { setAlert } from '../../actions/alert'
import brand from '../../assets/img/brand.png'
import ellipsis from '../../assets/img/ellipsis.svg'
import UpdatePanel from './UpdatePanel'
import confirmDeletePanel from './admin-utils/confirmDelete'
import Alert from '../layout/Alert'



const Panels = ({ getPanels, deletePanel, updatePanel, logout, panels: { panels, loading, error } }) => {
  const [editingPanelId, setEditingPanelId] = useState(null)
  const setIdToNull = () => setEditingPanelId(null)
  const [showHideSidenav, setShowHideSidenav] = useState('menu')


   useEffect(() => {
     getPanels()
   }, [])


  // Toggle nav
  function toggleNav() {
    let css = (showHideSidenav === 'menu') ? 'menu open' : 'menu'
    setShowHideSidenav(css)
  }


    

  // Handling loading
  if (loading) return <Spinner/>

  return (
   <>
    <header className="header">
      <div className="header__left header__left--auth">
       <ul className="breadcrumbs">
        <Link 
          style={{margin: '0'}}
          onClick={logout} to='/admin' 
          className="button button--secondary">Log out
        </Link>
        </ul>
      </div>
       <Link to='/' className='nav__home'>
         <img className="brand" src={brand} alt="Brand Name"/>
       </Link>

      <div className="header__right">
        <div onClick={toggleNav} className="nav__link" data-menu>
          <span>Menu</span>
          <img className="link__icon" src={ellipsis} alt="Menu"/>
        </div>
      </div>
      </header>


      <menu className={showHideSidenav}>
        <Link to='/admin-dashboard' className="menu__item">Dashboard</Link>
        <Link to='/users' className="menu__item">Users</Link>
        <Link to='/admin' onClick={logout} className="menu__item">Log Out</Link>
      </menu>

    <div className='main'>
     <section className="section">
      <div className="container tp--sm bp--md">
        <div className="content">

      <div className='section__header'>
       <h1>Panels</h1>
       <Link to='/add-panel' className="button button--primary">New Panel</Link>
      </div>

      <Alert />

      {panels.length < 1 && <p>No panels.</p>}

      {panels.map(panel => {
       if(editingPanelId === panel.id) {
         return (
           <div key={panel.id}>
            <UpdatePanel
              panel={panel} // state
              editPanel={updatePanel} // method up here
              setIdToNull={setIdToNull} // set editing brand id to props
              getPanels={getPanels} // to update panels view after edit
            />
          </div>
        )
      }
      return (
        <div className="orders" key={panel.id}>
         <Link to={`/panel/${panel.id}`} style={{textDecoration: 'none'}}> 
         
        <div className="orders__item">
          
        <div className="orders__graphic__wrapper">
          <img src={panel.icon} style={{width: '100%', height: '100%'}} />
          {/*<img className="orders__graphic" src={panel.icon} alt="icon"/>*/}
        </div>

        <div className="orders__info">
          <div className="orders__header">
          <h2>{panel.panel_name}</h2>
          <div className="orders__id">{panel.panel_prefix}</div>
          </div>
        </div>

        </div>

        </Link>
         <div className="buttons custom">
           <button 
             onClick={() => setEditingPanelId(panel.id)} 
             className="button__sm button__sm--primary" 
           >
             Edit
           </button>
           <button 
            onClick={() => confirmDeletePanel(panel.id, panel.panel_name, deletePanel)}
            className="button__sm button__sm--secondary"
           >
             Delete
           </button> 
         </div>
        </div>
       )
      })} 
       </div>
      </div>
     </section>
    </div>
  </>
  ) 
} 


const mapStateToProps = state => {
  //console.log('State:', state, state.panels)
  return  {
    panels: state.panels
  }
}


export default connect(
  mapStateToProps,
  { getPanels, deletePanel, updatePanel, setAlert, logout }
)(Panels)

// export default connect(
//   mapStateToProps,
//   { getPanels }
// )(withRouter(Panels))



// ALERT
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'

// AUTH
export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

// PANELS
export const GET_PANELS_SUCCESS = "GET_PANELS_SUCCESS"
export const GET_PANELS_ERROR = "GET_PANELS_ERROR"

export const GET_PANEL_BY_ID_SUCCESS = "GET_PANEL_BY_ID_SUCCESS"
export const GET_PANEL_BY_ID_ERROR = 'GET_PANEL_BY_ID_ERROR'

export const POST_PANEL_SUCCESS = "POST_PANEL_SUCCESS"
export const POST_PANEL_ERROR = "POST_PANEL_ERROR"

export const DELETE_PANEL_SUCCESS = "DELETE_PANEL_SUCCESS"
export const DELETE_PANEL_ERROR = "DELETE_PANEL_ERROR"

export const UPDATE_PANEL_SUCCESS = "UPDATE_PANEL_SUCCESS"
export const UPDATE_PANEL_ERROR = "UPDATE_PANEL_ERROR"

export const GET_UNUSED_KIT_IDS_SUCCESS = "GET_UNUSED_KIT_IDS_SUCCESS"
export const GET_UNUSED_KIT_IDS_ERROR = "GET_UNUSED_KIT_IDS_ERROR"

// PANEL ICONS
export const GET_ICONS_SUCCESS = "GET_ICONS_SUCCESS"
export const GET_ICONS_ERROR = "GET_ICONS_ERROR"

// USERS
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_ERROR = "GET_USERS_ERROR"

export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_ERROR = "GET_USER_ERROR"

export const ARCHIVE_USER_SUCCESS = "ARCHIVE_USER_SUCCESS"
export const ARCHIVE_USER_ERROR = "ARCHIVE_USER_ERROR"

export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS"
export const ACTIVATE_USER_ERROR = "ACTIVATE_USER_ERROR"

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import parseJwt from './JWTdecoder'

// Add token expiration checking
const AdminRoute = ({ component: Component, ...rest }) => {
   // Get and parse token 
   const authToken = localStorage.getItem('token')
   const decoded = parseJwt(authToken)
   let user_type
   let exp
   if(decoded){
      user_type = decoded.type
      exp = decoded.exp
   }


  if (authToken && Date.now() >= exp * 1000) {
    localStorage.setItem('sessionExpired', true)
  }

  return (
    <Route
      {...rest}
      render={props => {
        //if (token && user_type === 'admin') {
        if (authToken && user_type === 'admin' && Date.now() <= exp * 1000) {
          return <Component {...props} />
        }
        return <Redirect to="/admin" />             
      }}
    />
  )
}

export default AdminRoute
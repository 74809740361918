import {
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
  
    GET_USER_SUCCESS, 
    GET_USER_ERROR, 
  
    ARCHIVE_USER_SUCCESS, 
    ARCHIVE_USER_ERROR,

    ACTIVATE_USER_SUCCESS, 
    ACTIVATE_USER_ERROR,
  } from '../actions/types'
  
  const initialState = {
    users: [],
    user: '',
    error: '',
    loading: true,
  }
  
  export default function(state = initialState, action) {
    const { type, payload } = action
  
    switch (type) {
      // GET USERS
      case GET_USERS_SUCCESS:
        return {
          ...state,
          // panels: payload.reverse().slice(0, 8),
          users: payload.reverse(),
          loading: false
       }

       // GET USER
       case GET_USER_SUCCESS:
        return {
          ...state,
          user: payload,
          loading: false,
       }

        // ARCHIVE USER
        case ARCHIVE_USER_SUCCESS:
          return {
            ...state,
            user: payload,
            loading: false,
        }

        // ACTIVATE USER
        case ACTIVATE_USER_SUCCESS:
          return {
            ...state,
            user: payload,
            loading: false,
        }

        // ERRORS
        case GET_USERS_ERROR:
        case GET_USER_ERROR:
        case ARCHIVE_USER_ERROR:
        case ACTIVATE_USER_ERROR:
            return {
              ...state,
              error: payload,
              loading: false
        }
  
      default:
        return state
    }
  }
  
  